@import '../../const/colors.scss';
@import '../../const/fonts.scss';



.buy_video_dialog {
  margin: 16px;

  .buy_video_content {
    padding: 24px 0px;

    .title {
      text-align: center;
      font-size: 28px;
      line-height: 100%;
      letter-spacing: -0.0241176em;
      color: $violet_start;
      font-weight: bold;
    }

    .buy_video_content_price_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: $grey_light;
      margin-top: 20px;
      padding: 30px 20px;

      .buy_video_content_price_name {
        font-size: 20px;
        line-height: 100%;
        color: $violet_start;
      }

      .buy_video_content_price_amount {
        font-weight: bold;
        font-size: 20px;
        line-height: 100%;
        color: $violet_start;
      }
    }

    .select_payment_method {
      padding: 0px 20px;

      .payment_method {
        font-size: 20px;
        line-height: 100%;
        letter-spacing: -0.0241176em;
        font-weight: bold;
        color: $violet_start;
        margin: 16px;
      }

    }

    .buy_record_pricing {
      background: $white;
      box-shadow: 0px 2px 8px rgba(70, 69, 95, 0.3);
      border-radius: 4px;
      margin: 30px 20px 20px 20px;

      .buy_record_pricing_amount {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $grey_light;
        padding: 16px 21px 10px 21px;
        font-size: 18px;
        line-height: 24px;
        color: $violet_start;
      }

      .buy_record_pricing_stripe {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 21px 4px 21px;
        font-size: 14px;
        line-height: 19px;
        color: $violet_start;

        .opacity {
          opacity: 0.5;
        }
      }

      .buy_record_pricing_service {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px 21px 20px 21px;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: flex-end;
        color: $violet_start;

        .opacity {
          opacity: 0.5;
        }

      }
    }

    .buy_record_total {
      font-size: 18px;
      line-height: 24px;
      color: $violet_start;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 20px;
    }

    .buy_video_buttons {
      display: flex;
      justify-content: center;
      padding-top: 24px;

      .buy_video_cancel {
        background: $grey_disabled;
        border-radius: 2px;
        margin: 0 10px;
        width: 140px;
      }

      .buy_video_accept {
        margin: 0 10px;
        width: 140px;

        .button {
          background: $violet_start;
        }

        .disactive {
          border: 0px;
          opacity: 0.3;
          color: $white;
        }
      }

      .button {
        border: 0;
        color: $white;
        font-family: $RedHat;
        font-size: 16px;
        font-style: normal;
        font-weight: bold;
        line-height: 19px;
        padding: 10px;
        text-align: center;
        width: 100%;
      }
    }


  }
}