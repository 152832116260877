@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.attendGroupBlock {
  .attendGroupPayment {
    color: #43425d;
    display: flex;
    font-family: $RedHat;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    justify-content: space-between;
    letter-spacing: 0.02em;
    line-height: 100%;
    margin: 28px 20px 18px;

    span {
      font-weight: bold;
      margin-left: auto;
    }
  }

  .attendGroupCardList {
    margin: 0px 20px;
    max-height: 227px;
    overflow: auto;

    .cardItem {
      margin-left: 16px;
      padding: 14px 0;
    }
  }

  .attendGroupPromocode {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0px 20px 0px 26px;
    padding: 16px 0;
    position: relative;

    .MuiCheckbox-root {
      padding: 10px;
    }

    svg {
      margin-right: 7px;
    }

    .fieldPromo {
      background: #f4f4f7;
      border-radius: 4px;
      border: 0;
      color: #43425d;
      font-family: $RedHat;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      line-height: 19px;
      padding: 10px;
      width: 358px;
    }

    .wrongCode {
      bottom: 18px;
      color: #ff2d55;
      font-family: $RedHat;
      font-size: 10px;
      font-style: normal;
      font-weight: normal;
      left: 108px;
      line-height: 13px;
      position: absolute;
    }

    .promoValue {
      font-weight: bold;
      position: absolute;
      right: 10px;
    }
  }

  .attendGroupAddPM {
    margin: 0 20px;

    button {
      background: none;
      border: none;
      color: #598e7e;
      font-family: $RedHat;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      margin: 8px 8px 8px 17px;
      padding: 16px 0;

      &:focus {
        outline: none;
      }
    }
  }

  .attendGroupPaymentData {
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(70, 69, 95, 0.3);
    border-radius: 4px;
    overflow: auto;
    margin: 20px;
    padding: 20px;

    .amount {
      font-family: $RedHat;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      span {
        margin-left: auto;
        font-weight: bold;
      }
    }

    .depositeDate,
    .destination,
    .type,
    .serviceFee {
      font-family: $RedHat;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      display: flex;
      justify-content: space-between;

      span {
        font-family: $RedHat;
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        margin-left: auto;
        opacity: 0.5;
      }
    }
  }

  .attendGroupTotal {
    color: #43425d;
    display: flex;
    font-family: $RedHat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    justify-content: space-between;
    line-height: 100%;
    margin: 17px 20px 22px;
  }

  .attendGroupButtons {
    display: flex;
    justify-content: center;
    padding-bottom: 51px;

    .attendGroupCancel {
      background: #a8a7bc;
      border-radius: 2px;
      margin: 0 10px;
      width: 140px;
    }

    .attendGroupPay {
      margin: 0 10px;
      width: 140px;

      .button {
        background: #43425d;
      }

      .disactive {
        border: 0px;
        opacity: 0.3;
        color: #ffffff;
      }
    }

    .button {
      border: 0;
      color: #ffffff;
      font-family: $RedHat;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      line-height: 19px;
      padding: 10px;
      text-align: center;
      width: 100%;
    }
  }
}

.attendGroupSocial {
  margin: 10px 9px;
  position: absolute;
  top: 0;
  z-index: 3;

  .attendIcon {
    background-color: $violet_dark_1;
    padding: 8px;
    margin-left: 4px;
  }
}

.imageWrapper {
  background-position: center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-color: black;
  position: relative;
  width: 100%;
  height: 336px;
  min-height: 336px;
  display: flex;

  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }

  .attendGroupName {
    color: $white;
    font-family: $RedHat;
    font-size: 28px;
    font-style: normal;
    font-weight: bold;
    line-height: 100%;
  }

  .attendGroupInfo {
    display: flex;
    z-index: 1;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
  }


  .dateTime {
    margin-right: 20px;
    margin-bottom: 0;
  }

  .imageBackground {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    bottom: 0;
    height: 200px;
    left: 0;
    position: absolute;
    right: 0;
  }
}
