@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.selectsBlock {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .blockLabel {
    width: 100%;
    font-family: $RedHat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #43425D;
  }

  .wdayButton {
    background: #FFFFFF;
    border: 1px solid #F4F4F7;
    box-sizing: border-box;
    border-radius: 4px;
    width: 40px;
    height: 40px;

    .button {
      height: 100%;
    }
  }
}
