@import '../../../const/colors.scss';
@import '../../../const/fonts.scss';

.profile_card {
  background: $white;
  border-radius: 5px;
  box-shadow: 0px 2px 5px $violet_start_1;
  padding: 20px 20px 28px 20px;
  display: flex;
  margin-top: 20px;
  line-height: 1.5em;
  position: relative;
  .profile_card_сontent_header_actions {
    display: inherit;
    position: absolute;
    right: 0;
    margin-right: 10px;
    margin-top: -10px;
    .profileActionsIcon:focus {
      outline: none;
    }
    .share-popup-content {
      top: unset;
      left: unset;
      margin-left: -153px;
    }
  }

  .userActions {
    display: flex;
    align-items: center;

    .followAction {
      width: 70px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $grey_normal;
      margin-right: 10px;
    }

    img {
      width: 24px;
    }

    .profile_card_followers {
      margin-left: auto;
    }

    .subscribed {
      color: $rose;
    }
  }

  .master_mind_avatar {
    width: 215px;
    height: 215px;
    font-size: 2.5em;
  }

  .profile_card_content {
    width: 100%;
    padding: 0px 0px 0px 19px;

    .profile_card_content_title {
      font-size: 18px;
      line-height: 24px;
      color: $violet_start;
      padding: 10px 0px 0px 0px;
      margin-bottom: 5px;
      font-weight: bold;
    }

    .profile_card_content_address {
      font-size: 16px;
      line-height: 21px;
      color: #7e7d8f;
    }

    .profile_card_content_rating {
      .MuiRating-root {
        color: $violet_start;
        display: flex;
        margin-top: 7px;

        .MuiSvgIcon-fontSizeInherit {
          width: 16px;
          height: 16px;
        }
      }
    }

    .profile_card_content_position {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 21px;
      color: #43425d;
      padding-top: 10px;
      span {
        text-transform: capitalize;
      }
    }

    .button-wrapper {
      &.follow {
        margin-top: 20px;
        background: none;
        margin-left: 0;
        padding: 0;
        margin-bottom: 30px;
        border-radius: none;

        button {
          padding: 8px 30px;
          border-radius: 5px;
          font-family: $RedHat;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          color: #ffffff;
          border: 0;
          width: 125px;

          :focus {
            outline: none;
          }
        }

        &.unfollow {
          button {
            background: #f4f4f7;
            color: #7e7d8f;
          }
        }
      }
    }

    .profile_card_content_about {
      margin-top: 15px;
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 24px;
      color: $violet_start;
      padding-bottom: 10px;
      text-align: left;
      word-break: break-word;
    }

    .profile_card_content_give_rate {
      display: flex;
      //padding from top 10 here
      margin: 10px 0px 0px 0px;
      align-items: center;

      .profile_card_content_give_rate_text {
        font-size: 18px;
        line-height: 24px;
        color: $violet_start;
      }

      .MuiRating-root {
        color: $violet_start;
        display: flex;
        //and 10px from top here = 20px)))
        padding: 10px 0 0 10px;

        .MuiSvgIcon-fontSizeInherit {
          width: 28px;
          height: 28px;
        }
      }
    }
  }

  &_influencerLabel{
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: $violet_start;
    margin-bottom: 20px;
  }
}

@media (min-width: 320px) and (max-width: 539px) {
  .profile_card {
    display: block;
    text-align: center;
    .master_mind_avatar {
      width: 215px;
      height: 215px;
      margin-left: auto;
      margin-right: auto;
    }
    .profile_card_content {
      text-align: center;
      .profile_card_content_give_rate {
        // flex-direction: column;
      }

      .profile_card_content_rating {
        width: fit-content;
        margin: auto;
      }
      .profile_card_content_give_rate {
        text-align: left;

        .MuiRating-root {
          color: $violet_start;
          display: flex;
          //and 10px from top here = 20px)))
          padding: 0;
          .MuiSvgIcon-fontSizeInherit {
            width: 28px;
            height: 28px;
          }
        }
      }
    }
  }
}

.subscribeTitle {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  font-size: 28px;
  font-weight: 700;
  font-family: Red Hat Display;
  color: $violet_start;
}

@media (min-width: 540px) and (max-width: 719px) {
  .profile_card {
    margin: 20px 10px 0 0;
    .master_mind_avatar {
      width: 150px;
      height: 150px;
    }
    .profile_card_content {
      .profile_card_content_address {
        padding-top: 10px;
      }
      .button-wrapper {
        &.follow {
          button {
            padding: 13px 30px;
            border-radius: 5px;
          }
        }
      }
    }
  }
}

@media (min-width: 720px) and (max-width: 959px) {
  .profile_card {
    margin: 20px 0px 0px;
    .master_mind_avatar {
      width: 200px;
      height: 200px;
    }
    .profile_card_content {
      .profile_card_content_address {
        padding-top: 10px;
      }
      .button-wrapper {
        &.follow {
          button {
            padding: 13px 30px;
          }
        }
      }
    }
  }
}

@media (min-width: 960px) and (max-width: 1199px) {
  .profile_card {
    margin: 20px 10px 0 0;
    .master_mind_avatar {
      width: 200px;
      height: 200px;
    }
    .profile_card_content {
      .profile_card_content_address {
        padding-top: 10px;
      }
      .button-wrapper {
        &.follow {
          width: unset !important;
          button {
            padding: 13px 30px;
          }
        }
      }
    }
  }
}
