@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.mmProfile_wrapper {
  width: 100%;
  padding: 0 10px;
  .listBlock{
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 21%;
    .itemBlock{
      margin-bottom: 0 !important;
    }
  }
}

.profileTabs {
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(67, 66, 93, 0.1);
  margin-top: 30px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  .tabButton {
    border: 0;
    color: #A8A7BC;
    cursor: pointer;
    display: flex;
    font-family: $RedHat;
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
    justify-content: space-between;
    line-height: 17px;
    padding: 10px 14px;
    text-transform: uppercase;

    span{
      width:100%;
    }

    img{
      width: 19px;
    }
  }

  .active {
      background: #43425D;
      box-shadow: 0px 1px 5px rgba(67, 66, 93, 0.2);
      border-radius: 4px;
      color: #FFFFFF;
  }
}

.personData {
  display: flex;
  flex-direction: row;

  .personContent{
    .emptyBlock{
      .filterBlock{
        margin-bottom: 10px;
        padding: {
          left: 0;
          right: 0;
        };
      }
    }
  }
  .dailyBlock,
  .emptyBlock{
    margin-left: 0;
    margin-right: auto;
    margin-top: 0;
    // width: 520px;
    overflow-x: hidden;

    .datePickerBlock {
      button {
        width: 114px;
        height: 25px;
        background: $violet_start;
        border-radius: 4px;
        margin-top: 9px;
        font-family: $RedHat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: $white;
        padding: 0;

        .time-picker-time-label {
          width: 68px;
          display: inline-block;
          font-variant-numeric: tabular-nums;
        }

        img {
          margin-top: -3px;
        }
      }
    }

    .wdayButton {
      background: #FFFFFF;
      border: 1px solid #F4F4F7;
      box-sizing: border-box;
      border-radius: 4px;
      width: 40px;

      .button {
        height: 100%;
      }
    }
  }

  .personContent {
    margin-right: 0;
    margin-left: 0;
    width: 100%;

    .filterBlock {
      background: none;
      padding-bottom: 0;
      margin-bottom: 10px;
      .group.tags {
        display: flex;
        justify-content: flex-start;
        column-gap: 3px;
        margin: 0;
        flex-wrap: wrap;
        row-gap: 5px;
        .button-wrapper.privateButton{
          margin: 0;
          button {
            white-space: nowrap;
            height: 25px !important;
            margin: {
              right: 0;
              left: 0;
            };
            padding: 0 5px !important;
          }
        }
      }

      .MuiDivider-root {
        border: 0;
        height: 0;
      }

      .privateButton {
        button {
          background: #F4F4F7;
          border-radius: 2px;
          border: 0 !important;
          color: #9DA4B3;
          font-family: $RedHat;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 19px;
          margin-top: 10px;
          margin-bottom: 10px;
          padding: 4px 3px;
        }
      }

      .active {
        button {
          color: #FFFFFF;
          background: #43425D;
        }
      }

      .group {
        &.tags{
          // margin: 0 16px;
          padding-bottom: 6px;

          .button-wrapper{
            button{
              padding: 4px 4px;
            }
          }
        }
      }

      .dayTime {
        background: #F4F4F7;
        border-radius: 5px;
        padding: 10px;
        margin: 0;

        .wdayButton {
          button {
            background: #FFFFFF;
            border-radius: 4px;
            border: 0;
            color: #A8A7BC;
            font-family: $RedHat;
            font-size: 14px;
            font-style: normal;
            font-weight: normal;
            line-height: 19px;
            margin-bottom: 0;
            margin-top: 0;
            padding: 3px 6px;
          }
        }

        .active {
          button {
            background: #43425D;
            color: #FFFFFF;
          }
        }
      }
    }

    .lsrecordsList {
      .filterBlock {

        .group.tags {
          margin: 0;
          .privateButton {
            margin-right: 0;
          }
        }

      }

      .emptyBlock{
        padding-left: 0;
        padding-right: 0;
      }

      .dropDownTabs {
        background: #FFFFFF;
        border-radius: 5px;
        box-shadow: 0px 2px 5px rgba(67, 66, 93, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 30px;
        margin-top: 30px;
        padding: 0;

        .tabButton {
          border: 0;
          color: #A8A7BC;
          display: flex;
          font-family: "Red Hat Display", sans-serif;
          font-size: 13px;
          font-style: normal;
          font-weight: normal;
          justify-content: space-between;
          line-height: 17px;
          padding: 10px 14px;
          text-transform: uppercase;

          img {
            width: 19px;
          }
        }

        .active {
          background: #43425D;
          border-radius: 4px;
          box-shadow: 0px 1px 5px rgba(67, 66, 93, 0.2);
          color: #FFFFFF;
        }
      }

      .groupLsRecords {
        .itemBlock {
          margin-left: 0;
          margin-right: 0;
          .imageBlock{
            width: 100%
          }
        }
      }
    }
  }

  .follow {
    margin-right: 0;
    width: 235px;
    align-self: baseline;
    margin-top: 0;

    h2 {
      font-weight: bold;
    }
  }

  .emptyBlock {
    margin-top: 0;
  }

  .emptyText {
    color: #A8A7BC;
    font-family: $RedHat;
    font-size: 21px;
    font-style: normal;
    font-weight: normal;
    line-height: 27px;
    margin: 10px auto;
    text-align: center;
  }
}

@media (min-width: 320px) and (max-width: 820px) {
  .mmProfile_wrapper {
    padding: 0 10px;
  }
  .profileTabs {
    flex-direction: column;
  }

  .personData {
    flex-direction: column;
    margin-top: 30px;

    .dailyBlock{
      width: 100%;
    }

    .follow{
      margin-left: auto;
      margin-right: auto;
      margin-top: 11px;
      text-align: left;
      width: 100%;

      h2 {
        text-align: left;
        font-weight: bold;
      }

    }

    .personContent {
      width: 100%;
      .emptyBlock{
        .filterBlock{
          padding: {
            left: 0;
            right: 0;
          };
        }
      }
      .filterBlock {
        padding: 20px 20px 0;
        .group.tags{
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          column-gap: 5px;
        }
        .dayTime {
          padding: 10px;

          .wdayButton {
            padding-left: 3px;
            padding-right: 3px;
            button {
              margin-right: 4px;
            }
          }
        }
      }
    }

  }
}
@media (min-width: 540px) and (max-width: 719px) {
  .mmProfile_wrapper {
    padding: 0 10px;
  }
  .profileTabs {
    .tabButton {
      align-items: center;
      padding: 12px 8px;

      span{
        font-size: 10px;
      }
    }
  }

  .personData {
    flex-direction: column;
    margin-top: 30px;
    .emptyBlock{
      .personContent{
        .filterBlock{
          padding: {
            left: 0;
            right: 0;
          };
        }
      }
    }
    .dailyBlock{
      width: 100%;
    }

    .follow{
      margin-top: 11px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }

    .personContent {
      width: 100%;

      .filterBlock {
        padding: 20px;

        .dayTime {
          padding: 10px;

          .wdayButton {
            padding-left: 3px;
            padding-right: 3px;
            button {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 720px) and (max-width: 959px) {
  .mmProfile_wrapper {
    padding: 0 10px;
  }
  .profileTabs {
    .tabButton {
      align-items: center;
      padding: 10px 12px;
      span{
        font-size: 13px;
      }
    }
  }

  .personData {
    flex-direction: row;
    margin-top: 30px;

    .dailyBlock{
      width: 100%;
      min-width: 460px;
    }

    .follow{
      width: 320px;
    }

    .personContent{
      width: 100%;

      .filterBlock{
        padding: 20px 20px 0;

        .dayTime {
          padding: 10px 10px 0;

          .wdayButton {
            padding-left: 3px;
            padding-right: 3px;
            button {
              margin-right: 4px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 960px) and (max-width: 1100px) {
  .mmProfile_wrapper {
    padding: 0 10px;
  }
  .profileTabs {
    .tabButton {
      align-items: center;
      padding: 10px 12px;
      span{
        font-size: 11px;
      }
    }
  }

  .personData {
    flex-direction: row;

    .dailyBlock{
      min-width: 460px;
      width: 100%;
    }

    .follow{
      width: 260px !important;
    }

    .personContent{
      width: 100%;
      .filterBlock{
        padding: 0 20px;

        .dayTime {
          // padding: 10px;
          .wdayButton {
            padding-left: 3px;
            padding-right: 3px;
            button {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .personData {
    .personContent{
      //width: 495px;
      width: 100%;

      .filterBlock{

        .dayTime{

          .wdayButton{
            padding-left: 3px;
            padding-right: 3px;
            button{

            }
          }
        }
      }
    }
  }
  .itemBlock {
    .imageBlock {
      //max-width: 100%;
    }
  }
}

.profileTabsWrapper {
  width: 100%;

  @media (max-width: 800px) {
    padding: 0 10px;
  }
}

.profileTabsContainer {
  display: flex;
  width: 100%;

  @media (max-width: 800px) {
    flex-direction: column;
  }
}