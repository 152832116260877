@import '../../../const/colors.scss';
@import '../../../const/fonts.scss';

.messageBlock {
  padding: 55px 80px 50px;

  .messageTitle {
    font-family: $RedHat;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 100%;
    color: #43425D;
    margin-bottom: 36px;
    text-align: center;
    letter-spacing: 0.02em;
  }

  .messageText {
    font-family: $RedHat;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 124%;
    color: #43425D;
    margin-bottom: 50px;
    text-align: center;
  }

  .messageActions {
    display: flex;
    justify-content: center;

    .MDCancel {
      background: #A8A7BC;
      border-radius: 4px;
      margin: 0 10px;
    }

    .MDDelete {
      background: #43425D;
      border-radius: 4px;
      margin: 0 10px;
    }

    button {
      border: 0;
      width: 140px;
      padding: 10px;
      font-family: $RedHat;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #FFFFFF;
    }
  }
}

@media (min-width: 960px) and (max-width: 1199px) {
  .messageBlock {
    padding: 60px 80px 50px 80px;

    .messageTitle {
      font-weight: bold;
      font-size: 28px;
      line-height: 28px;
      letter-spacing: -0.0241176em;
      margin-bottom: 30px;

    }

    .messageText {
      font-size: 22px;
      line-height: 26px;
      letter-spacing: -0.0241176em;
    }

    .messageActions {
      margin-bottom: 0px;

      .MDCancel {
        font-size: 16px;
        line-height: 19px;
      }

      .MDDelete {
        font-size: 16px;
        line-height: 19px;
      }

      button {}
    }
  }

}