@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.allCreators{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 20px 0;
  background: $white;
  box-shadow: 0px 2px 5px rgba(67, 66, 93, 0.1);
  border-radius: 5px;
  margin: {
    top: 20px;
    bottom: 10px;
  }

  &_title{
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
  }

  &_tabs{
    .buttonGroup{
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: {
        left: 0;
        right: 0;
      };
      button{
        width: initial;
      }
      .buttonText,
      .activeButtonText{
        font-size: 16px;
      }
    }
  }

  &_bnts{
    display: flex;
    justify-content: center;
    font-size: 16px;
    padding: {
      top: 50px;
      bottom: 30px
    };
    .grey{
      font-size: 16px;
      padding: 10px 60px;
    }
  }
}