@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.followers_amount_wrapper {
  background: $grey_white;
  border-radius: 2px;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  //margin-top: 20px;

  .text{
    margin: 2px 5px;
  }
}