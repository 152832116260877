.share-popup-wrapper{
    display: inline-block;
    position: relative;

    .share-popup-children-wrapper {
        .share-popup-content {
            // display: none;
            visibility: hidden;
            transition: visibility 0s 0.3s;
        }
    }

    .share-popup-children-wrapper:hover {
        .share-popup-content {
            // display: block;
            visibility: visible;
            transition: visibility 0s 0s;
        }
    }
  .share-popup-children-wrapper_click{
    .MuiIconButton-root{
      &:hover,
      &:focus{
        outline: none;
      }
    }
    .share-popup-content {
      display: none;
      transition: display 0s 0.3s;
    }
    &.show{
      .share-popup-content {
        // display: block;
        display: block;
        transition: display 0s 0s;
      }
    }
  }
}
