@import '../../../src/const/colors.scss';

.page_404 {
    padding: 40px 0;
    background: $white;
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {
    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
    background: linear-gradient(180deg, $black0 50%, $black8 100%);
    opacity: 0.4;
}


.four_zero_four_bg h1 {
    font-size: 80px;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_404 {
    color: $white;
    padding: 10px 20px;
    background: $green_light;
    margin: 20px 0;
    display: inline-block;
}

.contant_box_404 {
    margin-top: -50px;
}