@import '../../../const/colors.scss';
@import '../../../const/fonts.scss';


.mm_channels {
    background: $white;
    border-radius: 5px;
    box-shadow: 0px 2px 5px $violet_start_1;
    margin: 0px 0;
    padding: 30px 20px 100px;

    .mm_channels_channel {
        display: flex;
        margin-bottom: 30px;
        .channel_picture {
            width: 129px;
            height: 129px;
        }

        .mm_channels_channel_content {
            margin-left: 20px;
            width: 100%;
            .mm_channels_channel_title {
                font-size: 18px;
                line-height: 24px;
                color: $violet_start;
                font-weight: bold;
            }

            .mm_channels_channel_type {
                font-size: 12px;
                line-height: 16px;
                color: $grey_normal;
                padding-top: 5px;
            }

            .mm_channels_channel_owner {
                display: flex;
                align-items: center;
                padding-top: 10px;

                .chanel_owner_picture {
                    width: 16px;
                    height: 16px;
                }

                .chanel_owner_name {
                    font-size: 12px;
                    line-height: 16px;
                    color: $violet_start;
                    padding-left: 4px;
                }
            }

            .mm_channels_channel_desc {
                font-size: 16px;
                line-height: 21px;
                color: $violet_start;
                width: 100%;
                padding-top: 10px;
            }

            .mm_channels_channel_info {
                display: flex;
                justify-content: space-between;
                background: #F4F4F7;
                border-radius: 5px;
                padding: 8px 10px;
                margin-top: 10px;

                .mm_channels_channel_info_item {
                    font-size: 14px;
                    line-height: 19px;
                    color: $grey_disabled;
                }
            }

            .button-wrapper {
                &.join_chat {
                    margin-top: 20px;

                    button:focus {

                        outline: none;

                    }

                }
            }
        }
    }

}


@media (min-width: 320px) and (max-width: 539px) {
    .mm_channels {
        .mm_channels_channel {
            margin-bottom: 190px;
            position: relative;
            .channel_picture {
                width: 80px;
                height: 80px;
            }
    
            .mm_channels_channel_content {
                
                .absolute_position{
                    position: absolute;
                    left: 0;
                    margin-top: 10px;
                    width: inherit;
                }
            }
            
        }
    
    }
}
  @media (min-width: 540px) and (max-width: 719px) {
  
  
  
  
  }
  @media (min-width: 720px) and (max-width: 959px) { 
  
  
   
  
  }
  @media (min-width: 960px) and (max-width: 1199px) {
   

  }
  