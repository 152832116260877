@import '../../../../const/colors.scss';
@import '../../../../const/fonts.scss';


.subscribedCard{
  box-shadow: 0 2px 8px $violet_dark_3;
  border-radius: 4px;
  margin: 10px 22px;

  &_ownerAvatar{
    display: flex;
    align-items: center;
    .MuiAvatar-root{
      width: 24px;
      height: 24px;
      font-size: 0.9rem;
    }
  }
  &_header{
    padding: 20px 20px 0;
    display: flex;
    justify-content: space-between;
  }

  &_ownerData{
    display: flex;
  }
  &_ownerWrapper{
    font-family: $RedHat;
    font-weight: 400;
    font-size: 10px;
    color: $grey_disabled;
    margin-left: 5px;
  }

  &_ownerName{
    font-family: $RedHat;
    font-size: 14px;
    line-height: 19px;
    color: $violet_start;

  }

  &_settingsBtn{
    button{
      outline: none;
      border: 1px solid $grey_white;
      box-sizing: border-box;
      border-radius: 2px;
      font-size: 12px;
      line-height: 16px;
      color: $violet_start;
      background-color: $white;
    }
  }

  &_packageName{
    font-family: $RedHat;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: $violet_start;
    margin: 5px 20px 12px;
  }

  &_packageData{
    font-family: $RedHat;
    margin: 5px 20px 20px;
    font-size: 10px;
    line-height: 13px;
    color: $grey_normal;
  }

  &_bottom{
    background-color: $grey_light;
    padding: 16px 20px;
    color: $green_normal;
    font-family: $RedHat;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    .bold{
      font-weight: 700;
    }
  }
}