@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.loader {
  margin-top: 300px;
}

.greyBackground {
  background: $grey_light;
  display: flex;
  justify-content: center;
  width: 100%;
}

.questionsBlock {
  background: $white;
  border-radius: 5px;
  box-shadow: 0px 2px 5px $violet_start_1;

  form {
    padding-left: 0;
    padding-right: 0;
  }

  .title {
    color: $violet_start;
    font-family: $RedHat;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    line-height: 26px;
    text-align: center;
  }

  .question {
    display: block;
    margin-top: 30px;

    .text {
      align-items: flex-end;
      color: $green_normal;
      display: flex;
      font-family: $RedHat;
      font-size: 18px;
      font-style: normal;
      font-weight: normal;
      line-height: 24px;

      
    }

    .questionsInput {
      width: 100%;
      
      input {
        align-items: flex-end;
        color: $violet_start;
        display: flex;
        font-family: $RedHat;
        font-size: 18px;
        font-style: normal;
        font-weight: normal;
        line-height: 24px;
      }
    }

    .radioInput {
      width: 100%;
      margin-left: -4px;

      .radioOptions {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .MuiFormControlLabel-root {
          margin: 4px 0;
          width: 50%;

          .MuiFormControlLabel-label {
            margin-left: 6px;
            letter-spacing: 0.05em;
            font-family: $RedHat;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            color: #43425D;
          }
        }
      }
    }

    .btn_wrapper{
      width: 100%;
      text-align: center;
      margin: 16px 0px 16px;
    }

  }

  .end {
    margin-bottom: 50px;
  }
}

@media (min-width: 320px) and (max-width: 539px) {
  .questionsBlock {
    height: auto;
    margin-top: 0;
    padding: 50px 10px;
    width: 100%;
  }
}

@media (min-width: 540px) and (max-width: 719px) {
  .questionsBlock {
    height: 690px;
    margin-top: 20px;
    padding: 50px 100px;
    width: 520px;

    .question {
      margin-left: 0px;

      .questionsInput {
        margin-top: -8px;
        width: calc(100% - 10px);

        input {
          padding: 4px 0;
        }

        label {
          font-size: 18px;
        }
      }

      .radioInput {
        margin-left: -8px;
        
        .PrivateSwitchBase-root-1 {
          padding: 6px 8px 4px 6px;
        }
      }
    }
  }
}

@media (min-width: 720px) and (max-width: 959px) {
  .questionsBlock {
    height: 690px;
    margin-top: 20px;
    // padding: 50px 180px;
    // width: 700px;

    .question {
      margin-left: 2px;

      .questionsInput {
        margin-top: -8px;
        width: calc(100% - 10px);

        input {
          padding: 4px 0;
        }

        label {
          font-size: 18px;
        }
      }

      .radioInput {
        margin-left: -10px;
        .PrivateSwitchBase-root-1 {
          padding: 6px 8px 4px 6px;
        }
      }
    }
  }
}

@media (min-width: 960px) and (max-width: 1199px) {
  .questionsBlock {
    height: 690px;
    margin-top: 20px;
    // padding: 55px 60px;
    // width: 460px;

    .question {
      margin-left: 38px;

      .questionsInput {
        margin-top: -8px;
        width: calc(100% - 20px);

        input {
          padding: 4px 0;
        }

        label {
          font-size: 18px;
        }
      }

      .radioInput {
        .PrivateSwitchBase-root-1 {
          padding: 6px 8px 4px 6px;
        }

        .radioOptions {
          margin-left: -7px;

          .MuiFormControlLabel-root {
            width: 46%;
          }
        }
      }
    }
    
    .button-wrapper {
      width: 300px;
    }
  }
}

@media (min-width: 1200px) {
  .questionsBlock {
    height: 690px;
    margin-top: 20px;
    padding: 50px 75px;
    width: 490px; 

    .question {
      margin-left: 18px;

      .questionsInput {
        margin-top: -8px;
        width: calc(100% - 10px);

        input {
          padding: 4px 0;
        }

        label {
          font-size: 18px;
        }
      }

      .radioInput {
        .PrivateSwitchBase-root-1 {
          padding: 6px 8px 4px 6px;
        }
      }
    }
  }
}
