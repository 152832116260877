@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.attendeesListBlock {
  margin: 20px;

  .attendeesListClose {
    padding: 10px 0;
    cursor: pointer;
    font-family: $RedHat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #43425D;
  }
  
  .list {
    max-height: 300px;
    overflow: auto;
  
    .userItem {
      display: flex;
      align-items: center;
      padding: 10px 0;

      .attendeesAvatar {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }

      .title {
        margin-right: auto;
        font-family: $RedHatText;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        color: #43425D;
      }

      .removeInvite {
        cursor: pointer;
      }
    }
  }
}
