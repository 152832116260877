@import '../../../../const/colors.scss';
@import '../../../../const/fonts.scss';

.group{
  display: flex;
  flex-wrap: nowrap;
}



@media (min-width: 320px) and (max-width: 539px) {
  .lsrecordsList {
    .filterBlock{
      .group.tags{
        flex-wrap: wrap;
        justify-content: flex-start;
        column-gap: 5px;
      }
    }
  }


}

.communityFilter{
  background-color: $grey_light;
  border-radius: 4px;
  font-size: 14px;
  &_head{
    display: flex;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
  }
  &_background{
    display: flex;
    padding: 9px;
    border-radius: 4px;
  }
  &_label{
    text-transform: uppercase;
    margin-left: 10px;
    margin-right: 45px;
    color: $grey_disabled;
  }

  &_main{
    background-color: $violet_main_filter;
    border-radius: 4px;
    color: $white;

    &.opened{
      background-color: $grey_light;
    }
    flex-grow: 1;
    &.mainCommunity{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 9px;
    }
  }

  .mainCommunity{
    position: relative;
    &_title{
      flex-grow: 1;
      font-size: 16px;
      margin-left: 35px;
      font-weight: 700;
    }
    &_logo{
      position: absolute;
      left: 0;
      transform: translateX(-50%);
      .personInfo-avatar{
        width: 50px;
        height: 50px;
      }
    }
    &_arrow{
      margin-right: 7px;
    }
  }

  &_listWrapper{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
    padding: 10px;

    @media (max-width: 769px) {
      grid-template-columns: repeat(auto-fill, 50px);
    }

    .selectCommunity{
      width: 50px;
      &.selected{
        .personInfo-avatar{
          border: 2px solid $violet_start;
        }
      }
      &_logo{
        position: relative;
        .check{
          position: absolute;
          bottom: 0;
          right: 0;
          z-index: 1;
        }
      }
      .personInfo-avatar{
        width: 50px;
        height: 50px;
      }
      .community_name{
        margin-top: 10px;
        font-size: 10px;
        text-align: center;
        word-break: break-all;
      }
    }
  }
}

.communityFilterByButtons{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .communityNameButton{
    font-size:12px;
    line-height: 20px;
    color: #9DA4B3;
    background-color: #E9E9F0;
    border-radius: 2px;
    padding: 0 5px;
    cursor: pointer;
    &.selectedByButtonCommunity{
      background-color: #43425D;
      color:white
    }
  }
}
