@import '../../../const/colors.scss';
@import '../../../const/fonts.scss';

.totalBlock {
  background: $white;
  border-radius: 4px;
  box-shadow: 0px 2px 8px $violet_dark_3;
  margin: 20px;
  padding: 20px;

  .totalTitle {
    color: $violet_start;
    font-family: $RedHat;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;

    span {
      font-weight: bold;
    }
  }

  .totalDesc {
    color: $violet_start;
    font-family: $RedHat;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    margin-bottom: 20px;
    opacity: 0.5;
  }

  .withdrawnBlock {
    color: $green_normal;
    display: flex;
    font-family: $RedHat;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    margin-top: 20px;
    
    span {
      font-weight: bold;
    }

    .withdrawButton {
      margin-left: auto;
      width: 63px;

      .small {
        width: 100%;
      }

      button {
        background-color: #FF2D55;
        color: $violet_start;
      }

      .disactive {
        background-color: #FFFFFF;
        color: #DADADA;
        border: 1px solid #DADADA;
      }
    }
  }
}

.withdrawPayBlock {
  margin: 60px 85px 50px;

  form {
    padding: 0 !important;

    .priceForm,
    .cardBrand {
      width: 100%;
      margin-bottom: 30px;

      .MuiFormLabel-root {
        font-family: $RedHat;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        text-transform: uppercase;
        color: $violet_start;
        opacity: 0.5;
      }

      .MuiInputBase-root {
        align-items: center;
        background: #F4F4F7;
        border-radius: 4px;
        color: #43425D;
        display: flex;
        font-family: "Red Hat Display", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        justify-content: space-between;
        line-height: 32px;

        &:before {
          border: 0;
        }
      }
    }

    .priceForm {
      .MuiInputBase-root {
        padding: 10px 15px;
      }
    }
    .cardBrand {
      .MuiInputBase-root {
        .MuiSelect-root {
          padding: 16px 24px 17px 15px;
        }
      }
    }
  }

  .withdrawPayTitle {
    color: $violet_start;
    font-family: $RedHat;
    font-size: 28px;
    font-style: normal;
    font-weight: bold;
    line-height: 100%;
    margin-bottom: 30px;
    text-align: center;
  }

  .withdrawPayData {
    color: $green_normal;
    display: flex;
    font-family: $RedHat;
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
    justify-content: space-between;
    line-height: 32px;
    margin-bottom: 20px;
    width: 100%;

    span {
      font-weight: bold;
    }
  }

  .withdrowFee {
    color: #43425D;
    display: flex;
    font-family: $RedHat;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    justify-content: space-between;
    line-height: 19px;
    margin-bottom: 30px;
    width: 100%;

    span {
      opacity: 0.5;
    }
  }

  .withdrawPayPrice {
    margin-bottom: 30px;
    
    .priceLabel {
      font-family: $RedHat;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      text-transform: uppercase;
      color: $violet_start;
      opacity: 0.5;
    }

    .priceData {
      align-items: center;
      background: $grey_light;
      border-radius: 4px;
      color: $violet_start;
      display: flex;
      font-family: $RedHat;
      font-size: 24px;
      font-style: normal;
      font-weight: 900;
      justify-content: space-between;
      line-height: 32px;
      padding: 16px 15px;

      span {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        opacity: 0.5;
      }
    }

    .blockLeft {
      justify-content: initial;

      .cardBrand {
        margin-right: 16px;
      }
    }
  }
}

.TDBlock {
  margin: 60px 85px 30px;

  .TDDownload {
    align-items: center;
    color: #43425D;
    display: flex;
    font-family: $RedHat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    justify-content: space-between;
    line-height: 24px;
    padding: 16px 16px 10px 15px;

    .TDDButton {
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        font-family: $RedHat;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 13px;
        text-decoration-line: underline;
        color: #43425D;
        margin-left: 10px;
      }
    }
  }

  .TDTitle {
    font-family: $RedHat;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 100%;
    text-align: center;
    color: $violet_start;
    margin-bottom: 30px;
  }

  .TDDetails {
    background: $white;
    box-shadow: 0px 2px 8px $violet_dark_3;
    border-radius: 4px;
    overflow: auto;

    .TDAmount {
      padding: 16px 16px 10px 15px;
      font-family: $RedHat;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $violet_start;

      span {
        margin-left: auto;
        font-weight: bold;
      }
    }

    .TDDepositDate,
    .TDDestination,
    .TDOtherData {
      margin: 12px 16px 11px 15px;
      font-family: $RedHat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $violet_start;

      span {
        opacity: 0.5;
      }
    }

    .TDOtherData {
      flex-direction: column;
      margin: 8px 16px 7px 15px;
      
      .TDOtheItem {
        justify-content: space-between;
        width: 100%;
        display: flex;
        margin: 4px 0;
      }
    }
  }

  .TDNetTotal {
    margin: 20px 16px 0 15px;
    font-family: $RedHat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $violet_start;

    span {
      font-weight: bold;
      margin-left: auto;
    }
  }
}

.MuiMenu-paper.MuiPopover-paper {
  height: auto;
  margin-left: -8px;
}

.codeSelect {
  height: 496px;

  .MuiMenu-paper {
    margin-left: -35px;
  }
}

@media (min-width: 320px) and (max-width: 539px) {
  .codeSelect {
    .MuiMenu-paper {
      margin-left: 15px;
    }
  }
  .TDBlock {
    margin: 60px 10px 30px;
  }
}
