@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.footer {
  background: $violet_start;
  box-shadow: 0px 4px 4px $black25;
  display: flex;
  position: relative;
  min-width: 320px;

  .logo{
    cursor: pointer;
  }
  .links {
    padding-top: 5px;
    
    .item {
      font-family: $RedHatText;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: $grey_light;
      cursor: pointer;

      &:hover {
        text-decoration: none;
        color: $grey_light;
      }
    }
  }

  .policy {
    align-items: center;
    background: $rose;
    display: flex;
    justify-content: center;
    left: 0;
    opacity: 0.9;
    position: absolute;
    right: 0;

    .text {
      color: $white;

      a {
        color: $white;
        border-bottom: 1px solid $white;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 539px) {
  .footer {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 10px 48px;
    height: 327px;
    justify-content: space-around;
  
    .links {
      margin-bottom: 10px;
      .item {
        display: block;
        padding: 11px 0;
        font-size: 14px;
      }
    }
  
    .policy {
      width: 100%;
      top: 327px;
      height: 320px;
      flex-wrap: wrap;
  
      .text {
        padding: 13px;
        font-size: 16px;
        font-size: 16px;
        line-height: 21px;
        max-width: 80%;
      }
    }
  }
}

@media (min-width: 540px) and (max-width: 719px) {
  .footer {
    flex-direction: column;
    align-items: flex-start;
    padding: 27px 10px 50px; // 53
    height: 327px;
    justify-content: space-around;

    .policy {
      top: 327px;
      height: 233px;
      padding: 18px 0 32px;
      flex-wrap: wrap;

      .text {
        padding: 13px;
        max-width: 80%;
      }
    }

    .links {
      margin-bottom: 10px;
      .item {
        display: block;
        padding: 11px 0;
        font-size: 14px;
      }
    }
  }
}

@media (min-width: 720px) and (max-width: 959px){
  .footer {
    flex-direction: column;
    align-items: flex-start;
    padding: 29px 10px 31px;
    height: 220px;
    justify-content: space-between;

    img {
      margin-left: 1px;
    }

    .policy {
      bottom: 220px;
      height: 188px;
      padding: 0 69px 32px;
      flex-wrap: nowrap;

      .text {
        padding: 13px;
      }
    }

    .links {
      margin-bottom: 15px;

      .item {
        padding-right: 30px;
        font-size: 14px;
        display: initial;
      }
    }
  }
}

@media (min-width: 960px) and (max-width: 1199px) {
  .footer {
    align-items: center;
    height: 100px;
    padding: 0 10px;
    justify-content: space-between;

    .policy {
      bottom: 100px;
      height: 141px;
      padding: 0 10px;

      .text {
        padding: 13px;
      }
    }

    .links {
      .item {
        font-size: 14px;
        line-height: 22px;
        margin-left: 30px;
      }

      .item:first-child{
        margin-left: 0px; 
      }
    }
  }
}

@media (min-width: 1200px) {
  .footer {
    align-items: center;
    height: 100px;
    padding: 0 100px;
    justify-content: space-between;

    .policy {
      bottom: 100px;
      height: 141px;
      padding: 0 91px;

      .text {
        margin: 0 15px;
      }
    }


    .links {
      .item {
        font-size: 14.7px;
        line-height: 31px;
        margin-right: 30px;
      }
    }
  }
}
