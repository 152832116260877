@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.SVBlock {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 57px 80px 50px;

  .SVTitle {
    // line-height: 100%;
    color: #43425D;
    font-family: $RedHat;
    font-size: 28px;
    font-style: normal;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
  }

  .SVsubText {
    color: #43425D;
    font-family: $RedHat;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 22px;
    margin-bottom: 30px;

    span {
      font-weight: bold;
    }
  }

  .fieldBlock {
    margin-bottom: 20px;
    width: 100%;

    .simpleInput {
      background: #F4F4F7;
      border-radius: 4px;
      width: 100%;
      
      .MuiInput-root {
        margin-top: 8px;
        padding-left: 15px;
        padding-right: 15px;

        &::before {
          border: 0;
        }
      }

      .withOutIcon {
        padding-right: 0;
      }
    }

    .fieldLabel {
      color: #A8A7BC;
      font-family: $RedHat;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      line-height: 19px;
      margin-left: 12px;
    }
  }
  
  .fieldImageBlock {
    height: 150px;
    margin-bottom: 20px;
    width: 148px;
  }
}
