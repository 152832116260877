@import '../../../const/colors.scss';
@import '../../../const/fonts.scss';

form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    align-items: flex-end;
    display: flex;
    justify-content: center;
  }

  .line {
    display: flex;
    justify-content: space-between;
    width: 100%;

    img {
      padding-top: 20px;
    }

    .code {
      display: flex;
      justify-content: space-between;
      width: 120px; // 30%;
    }
  }
}

.forgotPassword {
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: $green_normal;
  font-family: $RedHat;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;

  &:hover {
    color: $green_normal;
  }
}
.removeAutofill{
  .MuiInputBase-input:-webkit-autofill{
    animation-name:mui-auto-fill-cancel !important;
    opacity: 0;
  }
}

.grayBackground {
  background: $grey_light;
  border-radius: 0px 0px 5px 5px;
  text-align: center;
  overflow: auto;
}

.socialButtons {
  display: flex;
  justify-content: center;

  button {
    padding: 0;
    border: 0;
    background: initial;

    &:focus {
      outline: none;
    }
  }
}

.MuiFormHelperText-root.Mui-error {
  font-size: 9px;
  line-height: 13px;
  font-family: $RedHat;
  font-style: normal;
  font-weight: 500;
  margin-bottom: -15px;
  height: 13px;
}

.MuiMenu-paper.MuiPopover-paper {
  width: 330px;
  height: 400px;
  margin-left: -30px;
  margin-top: -4px;

  .codeItem {
    padding: 6px 16px !important;
  }

  li {
    border-bottom: 1px solid $grey_light;
    font-family: $RedHat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 36px;
    color: $violet_start;
    padding-left: 10px !important;

    &:hover {
      font-weight: bold;
    }

    .showCode {
      width: 85px;
    }

    .itemLabel {
      width: 210px;
    }
  }

  &::-webkit-scrollbar {
    width: 4px;
    margin: 5px 10px 5px 0;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: $grey_normal;
    border-radius: 2px;
  }
}

#logout {
  .MuiMenu-paper.MuiPopover-paper {
    width: auto;
    height: auto;
    margin: -0;
  }
}

// .dialogContent {
  .loginForm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    form {
      padding-top: 56px;

      .title {
        margin-bottom: 29px;
      }

      .phoneInput,
      .passwordInput,
      .codeBlock {
        font-family: $RedHat;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: #43425D;
      }

      .codeBlock {
        .MuiSelect-icon {
          margin-top: 5px;
        }
      }

      .line.end {
        margin-top: 15px;

        label {
          padding-left: 4px;
        }

        input {
          padding-left: 4px;
        }
      }
    }
  // }
}

@media (min-width: 320px) and (max-width: 539px) {
  form {
    .line {
      margin-bottom: 7px;

      .code {
        width: 120px; // 37%;
      }

      &.end{
        img {
          padding-right: 10px
        }
      }
    }
  }
  .MuiDialog-paperWidthSm {
    height: 100%;
    margin: 0 !important;
    max-height: 100% !important;
    width: 100%;

    // .dialogContent {
      form {
        padding: 45px 0 20px;
        margin: 0 auto;
        width: 300px;

        .line {
          margin-bottom: 7px;

          .code {
            width: 120px; // 37%;
          }
        }

        .line.end {
          margin-bottom: 26px;
        }

        .title {
          color: $violet_start;
          font-size: 28px;
          font-weight: bold;
          line-height: 100%;
          margin-bottom: 29px;
        }

        .codeBlock {
          width: 90px;
        }

        .phoneInput {
          width: 175px;
        }

        .passwordInput {
          width: 270px;
        }
      }

      .grayBackground {
        padding: 0 10px;
      }
    // }
  }

  .forgotPassword {
    margin: 30px auto;
    font-size: 16px;
    line-height: 21px;
  }

  .register {
    font-size: 16px;
    line-height: 21px;
    color: $violet_start;
    margin: 53px auto 30px;

    .signUp {
      font-weight: bold;
      color: $violet_start;

      &:hover {
        color: $violet_start;
        text-decoration: none;
      }
    }
  }

  .socialTitle {
    font-size: 16px;
    line-height: 21px;
    color: $violet_start;
    margin: 40px auto 10px;
  }

  .socialButtons {
    margin: 15px auto 40px;

    img {
      padding: 15px;
    }
  }
}

@media (min-width: 540px) and (max-width: 719px) {
  .MuiDialog-paperWidthSm {
    height: 100%;
    margin: 0 !important;
    max-height: 100% !important;
    width: 100%;

    // .dialogContent {
      form {
        padding: 45px 105px 23px;

        .line {
          margin-bottom: 7px;
        }

        .line.end {
          margin-bottom: 24px;
        }

        .title {
          color: $violet_start;
          font-size: 28px;
          font-weight: bold;
          line-height: 100%;
          margin-bottom: 30px;
        }

        .codeBlock {
          width: 90px;
        }

        .phoneInput {
          width: 205px;
        }

        .passwordInput {
          width: 298px;
        }

        .button-wrapper {
          width: 300px;
        }
      }

      .grayBackground {
        padding: 0 85px;
      }
    // }
  }

  .forgotPassword {
    margin: 33px auto 28px;
    font-size: 16px;
    line-height: 21px;
  }

  .register {
    font-size: 16px;
    line-height: 21px;
    color: $violet_start;
    margin: 50px auto 30px;

    .signUp {
      font-weight: bold;
      color: $violet_start;

      &:hover {
        color: $violet_start;
        text-decoration: none;
      }
    }
  }

  .socialTitle {
    font-size: 16px;
    line-height: 21px;
    color: $violet_start;
    margin: 40px auto 10px;
  }

  .socialButtons {
    margin: 15px auto 40px;

    img {
      padding: 15px;
    }
  }
}

@media (min-width: 720px) and (max-width: 959px) {
  .dialogContent {
    width: 460px;
  }

    form {
      // padding: 60px 57px 20px;
      padding-top: 54px;

      .title {
        margin-bottom: 25px;
      }

      .line {
        margin-bottom: 7px;

        img {
          // height: 16px;
          width: 16px;
          margin-left: 3px;
        }
      }

      .line.end {
        margin-top: 14px !important;
        margin-bottom: 24px;
      }

      .title {
        color: $violet_start;
        font-size: 28px;
        font-weight: bold;
        line-height: 100%;
        margin-bottom: 21px;
      }

      .codeBlock {
        width: 90px;
      }

      .phoneInput {
        width: 190px;
      }

      .passwordInput {
        width: 310px;
      }
    }

    .grayBackground {
      padding: 0 85px;
    }
  }

  .forgotPassword {
    margin: 34px auto 28px;
    font-size: 16px;
    line-height: 21px;
  }

  .register {
    font-size: 16px;
    line-height: 21px;
    color: $violet_start;
    margin: 55px auto 30px;

    .signUp {
      font-weight: bold;
      color: $violet_start;

      &:hover {
        color: $violet_start;
        text-decoration: none;
      }
    }
  }

  .socialTitle {
    font-size: 16px;
    line-height: 21px;
    color: $violet_start;
    margin: 40px auto 10px;
  }

  .socialButtons {
    margin: 15px auto 40px;

    img {
      padding: 15px;
    }
  // }
}

@media (min-width: 960px) and (max-width: 1199px) {
  .dialogContent {
    width: 460px;
  }

    form {
      padding: 60px 75px 20px;

      .line {
        margin-bottom: 7px;

        .code {
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          width: 120px; // 30%;
        }
      }

      .line.end {
        margin-bottom: 24px;
      }

      .title {
        color: $violet_start;
        font-size: 28px;
        font-weight: bold;
        line-height: 100%;
        margin-bottom: 23px;
      }

      .codeBlock {
        width: 90px;
      }

      .phoneInput {
        width: 213px;
      }

      .passwordInput {
        width: 275px;
      }
    }

    .grayBackground {
      padding: 0 85px;
    }
  }

  .forgotPassword {
    margin: 30px auto;
    font-size: 16px;
    line-height: 21px;
  }

  .register {
    font-size: 16px;
    line-height: 21px;
    color: $violet_start;
    margin: 40px auto 30px;

    .signUp {
      font-weight: bold;
      color: $violet_start;

      &:hover {
        color: $violet_start;
        text-decoration: none;
      }
    }
  }

  .socialTitle {
    font-size: 16px;
    line-height: 21px;
    color: $violet_start;
    margin: 40px auto 10px;
  }

  .socialButtons {
    margin: 15px auto 40px;

    img {
      padding: 15px;
    }
  // }
}

@media (min-width: 1200px) {
  .dialogContent {
    padding-bottom: 20px;
    width: 490px;
  }

    form {
      padding: 57px 85px 20px;

      .line {
        margin-bottom: 7px;
      }

      .line.end {
        margin-bottom: 26px;
      }

      .title {
        color: $violet_start;
        font-size: 28px;
        font-weight: bold;
        line-height: 100%;
        margin-bottom: 25px;
      }

      .codeBlock {
        width: 90px;
      }

      .phoneInput {
        width: 195px;
      }

      .passwordInput {
        width: 295px;
      }
    }

    .grayBackground {
      padding: 0 85px;
    }
  }

  .forgotPassword {
    margin: 32px auto 30px;
    font-size: 16px;
    line-height: 21px;
  }

  .register {
    font-size: 16px;
    line-height: 21px;
    color: $violet_start;
    margin: 48px auto 30px;

    .signUp {
      font-weight: bold;
      color: $violet_start;

      &:hover {
        color: $violet_start;
        text-decoration: none;
      }
    }
  }

  .socialTitle {
    font-size: 16px;
    line-height: 21px;
    color: $violet_start;
    margin: 40px auto 10px;
  }

  .socialButtons {
    margin: 15px auto 40px;

    img {
      padding: 15px;
    }
  // }
}
