@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.lsrecordsList {
  padding: 0 20px;
  .listBlock{
    gap:40px
  }

  .creteVideoButton {
    align-items: center;
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(70, 69, 95, 0.3);
    color: #43425D;
    display: flex;
    font-family: $RedHat;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    height: 72px;
    line-height: 21px;
    margin: 14px 0 20px;
    padding-left: 20px;
    width: 100%;

    .videoIcon {
      align-items: center;
      background-color: #43425D;
      border-radius: 50%;
      display: flex;
      height: 32px;
      justify-content: center;
      margin-right: 10px;
      width: 32px;
    }
  }
}

@media (min-width: 320px) and (max-width: 539px) {}

@media (min-width: 540px) and (max-width: 719px) {}

@media (min-width: 720px) and (max-width: 959px) {}

@media (min-width: 960px) and (max-width: 1199px) {}

@media (min-width: 1200px) {}
