@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.subscribeBlock {
  background-repeat: no-repeat;

  .subscribeTopBlock {
    padding: 40px 0px 0px;
    margin-bottom: 30px;

    .subscribeTitle {
      height: 32px;
      margin-bottom: 10px;
      position: relative;

      .blueLine {
        background: linear-gradient(89.81deg, $violet_end_special 0%, $violet_simple0 101.49%);
        border-radius: 3px;
        height: 4px;
        position: absolute;
        right: 0;
        top: 7px;
        width: 362px;
      }

      .pingLine {
        background: linear-gradient(89.84deg, $rose_dark_0 0.69%, $rose 100.86%);
        border-radius: 3px;
        height: 4px;
        left: 0;
        opacity: 0.5;
        position: absolute;
        top: 13px;
        width: 357px;
      }

      .subscribeTitleText {
        color: $white;
        font-family: $RedHat;
        font-size: 32px;
        font-style: normal;
        font-weight: bold;
        left: 0;
        line-height: 100%;
        position: absolute;
        right: 0;
        text-align: center;
        text-transform: uppercase;
      }
    }

    .subscribeSubTitle {
      color: $grey_white;
      font-family: $RedHat;
      font-size: 21px;
      font-style: normal;
      font-weight: normal;
      line-height: 100%;
      margin-bottom: 72px;
      text-align: center;
    }

    .subscribeText {
      color: $violet_start;
      font-family: $RedHat;
      font-size: 21px;
      font-style: normal;
      font-weight: normal;
      line-height: 100%;
      text-align: center;

      .subscribeTextSecond {
        margin-top: 10px;

        span {
          font-weight: bold;
        }
      }
    }
  }

  .subscribeGroupCardList {
    margin: 0px 20px;
    max-height: 224px;
    overflow: auto;
  }

  .subscribeGroupAddPM {
    margin: 0 20px;

    button {
      background: none;
      border: none;
      color: #598E7E;
      font-family: $RedHat;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      margin: 8px;
      padding: 16px 0;

      &:focus {
        outline: none;
      }
    }
  }

  .subscribeButton {
    width: 450px;
    margin: 30px 20px;

    button {
      height: 60px;
      width: 100%;
      font-family: $RedHat;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      text-transform: uppercase;
      color: #FFFFFF;

      &:focus {
        outline: none;
      }
    }
  }

  &.succeseBlock {
    .subscribeTopBlock {
      padding-top: 40px;

      .subscribeTitle {
        height: 64px;

        .blueLine {
          top: 39px
        }

        .pingLine {
          top: 45px;
        }
      }
    }

    .subscribeText {
      color: $violet_start;
      font-family: $RedHat;
      font-size: 21px;
      font-style: normal;
      font-weight: normal;
      line-height: 100%;
      margin-top: 116px;
      padding: 0 30px;
      text-align: left;
    }
  }
}


@media (min-width: 1200px) {
  .subscribeForm {
    padding-top: 34px;
  }
}

@media (min-width: 960px) and (max-width: 1199px) {
  //.subscribeButton {
  //  width: 420px !important;
  //}
  //.closeButton {
  //  top: -2px !important;
  //  right: -2px !important;
  //}

  .subscribeBlock {

    .subscribeGroupAddPM {
      margin: 0px 29px !important;
    }
    .subscribeTopBlock {


      .subscribeTitle {


        .blueLine {}

        .pingLine {}

        .subscribeTitleText {
          line-height: 86% !important;
        }
      }

      .subscribeSubTitle {}

      .subscribeText {


        .subscribeTextSecond {


          span {}
        }
      }
    }

  }

  .subscribeGroupCardList {
    margin: 2px 38px !important;
  }

}
