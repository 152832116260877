
.badgeButton {
  background: initial;
  border: none;
  padding: 0 10px;

  &:focus {
    outline: none;
  }

  .MuiBadge-anchorOriginTopRightRectangle {
    top: 50%;
    margin-top: -9px;
  }
}
