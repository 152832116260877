.navMenuStyles {
    position: absolute;

    .dividerStyles {
        width: 80%;
        margin:0 auto;
    }

    @media (min-width: 320px) and (max-width: 425px) {
        width: 100%;
        display: block !important;
        position: initial;
    }
    
    @media (min-width: 425px) and (max-width: 539px) {
        width: 100%;
        display: flex !important;
        flex-wrap: wrap;
        position: initial;

        hr {
            display: none;
        }

        .searchButtonStyles {
            position: relative;
            left: 25px;
        }
    }

    @media (min-width: 768px) {
        display: block !important;
        left: 0;
    }

    @media (min-width: 960px) and (max-width : 1199px) {
        left: 0;
    }

    @media (min-width: 1200px) {
        left: 75px;
    }
}

.searchButtonStyles {
    display: flex;
    justify-content: center;
}