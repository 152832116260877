@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.button-wrapper {
  display: inline-block;
  font-size: 16px;

  .button {
    background-color: initial;
    font-weight: 500;
    border-radius: 4px;
    border: none;
    //box-sizing: border-box;
    color: $violet_dark;
    font-size: 16px;
    line-height: 21px;
    margin: 0;
    padding: 10px 30px;
    max-width: 100%;
    text-align: center;
  }

  .width100 {
    padding: 8px 0;
    line-height: 40px;
    width: 100%;
  }

  .width180 {
    padding: 8px 0;
    width: 180px !important;
  }

  .blue {
    border: 0;
    color: #fff;
    box-shadow: 0 2px 8px rgba(116, 170, 187, 0.8);
    background: linear-gradient(2.31deg, #69BBD5 1.96%, #83CEE5 102.09%);
  }

  .violetGradien {
    background: linear-gradient(0.62deg, $violet_start 1.96%, $violet_end 102.09%);
    box-shadow: 0 2px 5px $violet_dark_3;
    color: $white;
  }

  .whiteGradient {
    background: linear-gradient(0.62deg, $white_blue 1.96%, $white 102.09%);
    box-shadow: 0 4px 4px $black25;
    border: 0;
    color: $rose;
  }
  .whiteViolet {
    background: $white;
    box-shadow: 0 4px 4px $black25;
    border: 1px solid $violet_start;
    color: $violet_start;
    border-radius: 4px;
  }

  .greyGradient {
    color: $grey_normal;
    background: linear-gradient(2.31deg, #E1E1E1 1.96%, #E9E9F0 102.09%);
    box-shadow: 0 2px 5px rgba(126, 125, 143, 0.2);
    border-radius: 4px;
    border: 1px solid #e5e5e8;
  }

  .yellowGradient {
    background: linear-gradient(0.38deg, $yellow_start 1.96%, $yellow_end 102.09%);
    border-radius: 4px;
    border: 0;
    box-shadow: 0 2px 5px $yellow_dark_4;
    color: $violet_start;
  }

  .orangeGradient {
    background:  #F4A706;
    border-radius: 4px;
    border: 0;
    background: linear-gradient(360deg, #F2A207 0%, #FFCA00 96.35%);
    color: #fff;
  }

  .blueGradient {
    background: linear-gradient(0.38deg, $blue_start 1.96%, $violet_end_special 102.09%);
    box-shadow: 0 2px 5px $blue_normal_5;
    border-radius: 4px;
    color: $white;
  }

  .lightBlueGradient {
    background: linear-gradient(2.31deg, #69BBD5 1.96%, #83CEE5 102.09%);
    box-shadow: 0 2px 5px $blue_normal_5;
    border-radius: 4px;
    border: 0;
    color: $white;
  }

  .redGradient {
    background: linear-gradient(2.31deg, #FF2D55 1.96%, #EB6081 102.09%);
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(255, 45, 85, 0.5);
    color: #FFFFFF;
    font-family: $RedHat;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
  }

  .big60 {
    padding: 13px 50px;
    font-size: 24px;
    line-height: 32px;
  }

  .disactive {
    background: $grey_unsubscribe;
    border-radius: 4px;
    box-shadow: 0 2px 5px $violet_dark_1;
    color: $violet_white;
    font-family: $RedHat;
    font-style: normal;
    font-weight: 500;
    border: 0;
  }

  .green {
    background: $green_normal;
    border: 1px solid $green_normal;
    box-sizing: border-box;
    box-shadow: 0 2px 5px $green_normal_2;
    border-radius: 2px;
  }

  .small {
    font-family: $RedHat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $white;
    width: 53px;
    height: 22px;
    padding: 0;
  }

  .smaller130 {
    width: 130px;
    padding: 9px 0;
  }

  .smaller110 {
    width: 110px;
    padding: 8px;
  }

  .grey {
    background: $grey_white;
    box-shadow: 0 2px 5px $violet_simple_2;
    border-radius: 4px;
    font-family: $RedHat;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: $violet_start;
  }
}
.width100 {
  width: 100%;
}

.width320 {
  width: 320px;
}

.button_with_icon {
  margin-bottom: 21px;
  margin-right: 20px;
  display: flex;
  background: none;
  padding: 0;
  border: none;
  font-family: $RedHat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  span {
    margin-left: 16px;
    width: 100%;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    text-align: left;
  }
}

.toggleSaveBtn{
  display: inline-block;
  color: $grey_normal;
  font-size: 12px;
  cursor: pointer;
  transform: translateX(10px);
  button{
    padding: 10px;
  }
  &_title{
    padding: 0;
    &:hover ~ .MuiIconButton-root {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}

@media (min-width: 960px) and (max-width: 1199px) {
  .smaller130 {
    padding: 6px 0 !important;
    font-size: 16px !important;
  }

}

@media (min-width: 1199px){
  .big60 {
    font-size: 16px !important;
  }

}
