@import '../../../const/colors.scss';
@import '../../../const/fonts.scss';

.mMFollowing {
  background: $white;
  border-radius: 5px;
  box-shadow: 0px 2px 5px $violet_start_1;
  margin: 20px 10px;
  padding: 30px 20px 10px;
  position: relative;
  width: inherit;
  overflow-x: hidden;
  &_creators_wrapper{
    position: relative;
    height: 10.4em;
    width: 100%;
  }
  &_creators{
    position: absolute;
    max-width: inherit;
    overflow-x: scroll;
    display: flex;
    column-gap: 10px;
    width: 100%;
    bottom: 0;
    top: 0;
    align-items: start;
    &::-webkit-scrollbar {
      display: block;
      height: 5px;
      margin: 5px 10px 5px 0;
    }

    &::-webkit-scrollbar-thumb {
      height: 5px;
      background: $grey;
      border-radius: 2px;
    }

    a{
      color: $violet_start;
      font-family: $RedHatText;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      &:hover{
        color: inherit;
        text-decoration: none;
      }
    }
    .creator{
      padding: 0 5px;
      .personInfo-avatar{
        width: 80px;
        height: 80px;
      }
      &_name{
      }
    }
  }

  .titleBlock {
    align-items: flex-start;

    h3 {
      font-family: $RedHat;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      margin-top: 0;
      margin-bottom: 20px;
      color: $violet_start;
    }
    .greenLink {
      font-family: $RedHatText;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: $green_normal;
    }
  }
  
  .personBlock {
    .personInfo {
      margin-bottom: 20px;
      display: flex;

      .textInfo {
        margin-right: auto;
        width: 100%;
      }

      .MuiAvatar-root {
        width: 50px;
        height: 50px;
      }

      .titleAndConnect {
        display: flex;
        width: 100%;
        justify-content: space-between;
        overflow: visible;
      }

      .location,
      .position {
        word-wrap: break-word;
      }

      .connectButtons {
        height: 20px;

        .small {
          width: auto;
          padding: 3px 5px;
          margin-left: 10px;
        }

        .profile {
          .small {
            background: $white;
            color: $violet_start;
            border: 1px solid $grey_white;
            box-sizing: border-box;
            border-radius: 2px;
          }
        }

        .message {
          .small {
            background: $violet_start;
            border: 1px solid $violet_start;
            box-sizing: border-box;
            box-shadow: 0px 1px 5px $violet_start_5;
            border-radius: 2px;
          }
        }
      }
    }
  }
}

@media (min-width: 960px) and (max-width: 1199px) {
  .mMFollowing {
    margin: 20px 10px;
  }
}

@media (min-width: 320px) and (max-width: 539px) {
  .mMFollowing {    
    .personBlock {
      .personInfo {
        .titleAndConnect {
          flex-wrap: wrap;
        }

        .connectButtons {
          height: auto;
          width: 100%;

          .small {
            margin-right: 10px;
            margin-left: 0;
          }
        }
      }
    }
  }
}
