@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.MuiDialog-container .dialogContent {
  width: 100%;
}

.MuiDialog-container .dialogContent .cardForm {
  .title {
    margin-bottom: 33px;
  }

  .likeCard {
    background: $grey_white;
    border-radius: 16px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 27px;
    position: relative;
    width: 100%;
    justify-content: space-between;

    &.openData {
      padding: 46px 20px 17px;
    }

    &.saveData {
      justify-content: space-between;
      margin-top: -22px;
      padding: 17px 20px;
    }

    .cardBrand {
      position: absolute;
      right: 24px;
      top: 24px;
    }

    .selectCountry {
      select {
        background: #F4F4F7;
        border-radius: 4px;
        border: 0;
        color: #43425D;
        font-family: "Red Hat Display", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
        padding: 7px 15px;
        text-transform: uppercase;
        width: 200px;
      }
    }

    label {
      &.cardCVC {
        width: 65px;
      }

      .cardFieldName {
        color: $grey_normal;
        font-family: $RedHat;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 13px;
        text-transform: uppercase;
      }

      .StripeElement {
        background: $grey_light;
        border-radius: 4px;
        padding: 6px;
      }

      .InputElement {
        color: $violet_start;
        font-family: $RedHat;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
      }
    }

    .cardNumber {
      margin-bottom: 24px;
      width: 100%;
    }

    .cardholderName {
      width: 195px; // 230px;

      input {
        background: $grey_light;
        border-radius: 4px;
        border: 0;
        color: $violet_start;
        font-family: $RedHat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
        padding: 7px 15px;
        text-transform: uppercase;
        width: 100%; // 209px;
      }
    }

    .cardDate {
      width: 70px;
    }
  }

}

@media (min-width: 320px) and (max-width: 539px) {

}

@media (min-width: 540px) and (max-width: 719px) {

}

@media (min-width: 720px) and (max-width: 959px) {
  .MuiDialog-container .dialogContent .cardForm{
    padding: 57px 59px 20px;

    .likeCard {
  
      &.openData {
        padding: 46px 21px 20px;
      }
    }

    .button-wrapper {
      padding: 0 14px;
    }
  }
}

@media (min-width: 960px) and (max-width: 1199px) {
  .MuiDialog-container .dialogContent .cardForm {
    padding: 57px 60px 42px;

    .button-wrapper {
      padding: 0 14px;
    }
  }
}

@media (min-width: 1200px) {
  .MuiDialog-container .dialogContent  .cardForm {
    padding: 57px 70px 42px;

    .button-wrapper {
      padding: 0 6px;
      width: 138%;
    }

    .button100 {
      width: 100%;
      padding: 0;
    }
  }
}
