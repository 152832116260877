@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.owner {
  align-items: center;
  display: inline-flex;
  margin-right: auto;
  z-index: 2;
  outline: none;
  margin-top: 5px;
  &:hover,
  &:focus{
    outline: none;
    text-decoration: none;
  }

  .avatar {
    width: 30px;
    height: 30px;
  }

  .ownerData {
    margin-left: 5px;

    .ownerName {
      font-family: $RedHatText;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 12px;
      color: $violet_start;
    }

    .ownerLocation {
      font-family: $RedHatText;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 13px;
      color: $violet_start;
      opacity: 0.8;
      margin-top: 3px;
    }
  }
}

.dateTime {
  background: $black8;
  opacity: 0.8;
  border-radius: 4px;
  padding: 7px 11px;
  z-index: 1;
  margin-bottom: 5px;
  width: 62px;

  .date {
    font-family: $RedHat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: $white;
    white-space: nowrap;
  }

  .time {
    font-family: $RedHat;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 11px;
    text-align: center;
    color: $grey_white;
    width: 100%;
  }
}
