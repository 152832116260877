@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.cardExpired {
  font-family: $RedHat;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  color: #FF2D55;
}

.codeSelect {
  height: 400px;

  .MuiMenu-paper {
    margin-left: -30px;
  }

  .codeItem {
    padding: 6px 16px !important;
  }
}
.select_distribution_groups_item{
  font-weight: 700 !important;
}

.categorySelect_placeholder {
  color: #B1B1BF;
  font-size: 10px;
  line-height: 13px;
  text-align: left;
}