@import '../../../../const/colors';
@import '../../../../const/fonts';

.subscriptionsPackagesBlock {
  flex-grow: 1;
  font-family: $RedHat, sans-serif;
  font-style: normal;

  .subscriptionsBlock {
    background: $white;
    border-radius: 5px;
    box-shadow: 0px 2px 5px $violet_start_1;
    margin: 18px 10px 20px;
    padding-bottom: 1px;

    .subcriptionTitle {
      padding-left: 20px;
      padding-top: 10px;

      .subcriptionTitleText {
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        color: $violet_start;
      }

      .subcriptionTitleCount {
        font-weight: 400;
        font-size: 15px;
        line-height: 15px;
        color: $grey_normal;
        padding-top: 25px;
        padding-left: 11px;
      }
    }
  }
}

.subscriptionsPackagesCardList {
  padding: 30px 20px 20px 20px;
  height: auto;
  display: flex;
  row-gap: 21px;
}

.subscriptionsPackagesCard {
  box-sizing: content-box;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: $white;
  border-radius: 5px;
  border: 1px solid #F4F4F7;
  box-shadow: 0px 2px 5px $violet_start_1;
  margin: 0 auto;

  &.currentSubscription{
    background-color: $rose;
    color: $white;

    .headerTitle{
      color: $white;
    }

    .footerCard{
      background-color: $violet_start;
      color: $rose;
      .pricePerMonthValue{
        color: $rose
      }
    }
  }
  &.selectedSubscription{
    border: 1px solid $rose;
  }
  &.addPackagesCard{
    min-height: 307px;
    cursor: pointer;
  }
  .subscriptionPackagesCardCommunities{
    padding-right: 20px;
    padding-left: 20px;
    justify-content: flex-start !important;
    flex-wrap: wrap;
    p{
      margin-left: 0;
    }
  }
}

.modalContent {
  justify-content: center;
}

.modalHeight {
  min-height: 324px;
}

.pageHeight {
  min-height: 385px;
}

.deActivatedCard {
  border: 1px solid $red_deactivatet !important;
}

.pricePerMonth {
  align-items: center;
  display: flex;

  .pricePerMonthValue {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: $violet_start;
  }

  .pricePerMonthText {
    font-size: 10px;
    padding-left: 5px;
    padding-bottom: 4px;
  }
}

.footerCard {
  padding: 8px 15px;
  display: flex;
  align-items: center;
  background: $grey_light;
  justify-content: space-between;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  .unsubscribeButton,
  .subscribeButton {
    color: #fff;
    background-color: $rose;
    font-family: $RedHatText;
    outline: none;
    width: 80px;
    font-size: 12px;
    line-height: 16px;
    padding: {
      top: 3px;
      bottom: 3px
    };
    &.MuiButton-root{
      text-transform: none;
    }
  }
  .unsubscribeButton{
    background-color: $grey_unsubscribe;
  }
}

.userContIcon {
  width: 16px;
  height: 12px;
  margin-right: 5px;
}

.countUsers {
  .activeBorder {
    border-radius: 2px;
    border: 1px solid $rose;
    padding: 3px 6px;

    .activeNumber {
      color: $rose;
    }
  }

  .greyBorder {
    border-radius: 2px;
    border: 1px solid $grey_disabled;
    padding: 3px 6px;

    .number {
      color: $grey_disabled;
    }
  }
}
.headerTop{
  padding-top: 20px;
  min-height: 41px;
}
.headerTitle {
  padding-left: 21px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: $violet_start;
  word-break: break-word;
}

.pointBtn {
  padding: 5px;
}

.headerStatus {
  padding-right: 14px;
}

.btnPointX3 {
  background: transparent;
  border: none;
  margin-right: -10px;
  margin-top: -10px;

  &:focus {
    outline: none;
  }
}

.popoverContent {
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  .popoverLink {
    .popoverLinkItem {
      height: 40px;
      padding-left: 40px;
      padding-top: 1px;
      font-weight: 400;
      font-size: 18px;
      line-height: 40px;
      color: $violet_start;
      border: none;
      background: transparent;

      &:focus {
        outline: none;
      }
    }
  }
}

.spanRecovery {
  padding: 2px 5px 2px 5px;
  margin:0;
  margin-left: 20px;
  border-radius: 2px;
  box-sizing: border-box;
  color: $white;
  background: $violet_start;
  height: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;

  span {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
#triangleSubscribe:after,
#triangleCurrent:after,
#triangleDeactivate:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  right: -10px;
  bottom: -9px;
  border-top: 10px solid $red_small_triange;
  border-right: 10px solid transparent;
  border-width: 6px 3px;
  border-style: solid;
  border-color: $red_small_triange $red_small_triange transparent transparent;
  transform: rotate(269deg);
}

#triangleSubscribe:after{
  border-top: 10px solid $rose;
  border-color: $rose $rose transparent transparent;
}

#triangleCurrent:after{
  border-top: 6px solid $grey_disabled;
  border-color: $grey_disabled $grey_disabled transparent transparent;
}

.subscriptionsLabel::after,
.subscriptionsCurrent::after,
.subscriptionsStatus::after {
  border: 20px solid transparent;
  border-left: 20px solid green;
  border-top: 20px solid green;
}

.subscriptionsLabel,
.subscriptionsCurrent,
.subscriptionsStatus {
  color: white;
  position: relative;
  display: flex;
  align-items: baseline;

  span {
    height: 100%;
    font-weight: 700;
    font-size: 8px;
    line-height: 10.88px;
    padding-left: 9px;
    padding-top: 5px;
    padding-right: 20px;
    white-space: nowrap;
  }
}
.subscriptionsLabel{
  span {
    padding-right: 5px;
    padding-left: 5px
  }
}
.headerBottom{
  padding-top: 10px;
  padding-bottom: 10px;
}
.headerBottom,
.headerTop{
  gap: 5px;
  .subscriptionsLabel,
  .subscriptionsCurrent,
  .subscriptionsStatus {
    height: 20px;
    span {
      background: $red_deactivatet;
      margin-right: -12px;
    }
  }

  .subscriptionsLabel{
    span {
      background: $rose;
    }
  }

  .subscriptionsCurrent{
    span {
      background: $grey_light;
    }
  }

  .subscriptionsCurrent{
    color: $violet_start
  }
}

.cardContent {
  padding: {
    left: 19px;
    right: 19px;
    bottom: 20px;
  };
  flex-grow: 1;

  .cardContentTitleTextSubText {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 13px;
    word-break: break-word;
  }

  .cardContentTitleText {
    font-size: 12px;
    line-height: 16px;
    img {
      width: 15px;
      height: 8px;
    }
  }
}

.listItems {
  list-style: none;
  margin: 0;

  .item {
    padding-bottom: 10px;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    align-items: center;
    &:last-child{
      padding-bottom: 0;
    }

    .check{
      display: flex;
      height: 16px;
      width: 16px;
      background: radial-gradient($white 59%, transparent 95%);
      box-shadow: 0 0.25rem 3px rgba(0,0,0,.15);
      border-radius: 50%;

    }

    .itemText {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      padding-left: 8px;
    }
  }
}

.buySubscriptionPopup{
  &_header{
    position: relative;
  }
  &_headerWrapper{
    color: $white;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: {
      top: 50px;
    };
  }
  &_title{
    font-family: $RedHat;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 20px;
  }
  &_owner{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .MuiAvatar-root{
      height: 50px;
      width: 50px;
      margin-bottom: 10px;
    }
  }
  &_ownerName{
    font-size: 14px;
    line-height: 12px;
    font-weight: 500;
    font-family: $RedHatText;
  }
  &_ownerLocation{
    font-family: $RedHatText;
    font-size: 10px;
    line-height: 13px;
    opacity: 0.8;
  }
  &_payment{
    padding: 20px;
    color: $violet_start;
  }
  &_cardList{
    .payment_method_item_wrapper{
      box-shadow: none;
      border-bottom: 1px solid $grey_white;
    }
  }
  &_paymentTitle{
    font-family: $RedHat;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: -0.0241176em;
    padding-bottom: 18px;
  }
  &_promocode{
    padding: {
      top: 18px;
      bottom: 18px;
    };
    border-bottom: 1px solid $grey_light;

    .MuiIconButton-label{
      border: 1px solid $grey_light;
      border-radius: 50%;
      overflow: hidden;
    }
    .fieldPromo{
      height: 40px;
      background-color: $grey_light;
      border-radius: 4px;
      border: none;
      padding-left: 10px;
      flex-grow: 1;

      &::placeholder{
        opacity: 0.5;
        color: $violet_start;
        font-size: 14px;
        line-height: 19px;
      }
    }
  }

  &_promocodeWrapp{
    display: flex;
    align-items: center;
  }

  &_paymentInfo{
    font-family: $RedHat;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    padding: 30px 10px 10px;
  }

  &_paymentData{
    box-shadow: 0px 2px 8px $violet_dark_3;
    border-radius: 4px;
    div{
      display: flex;
      justify-content: space-between;
      padding: 10px;
      border-bottom: 1px solid $grey_light;
      &:first-child{
        padding-top: 15px;
      }
      &:last-child{
        padding-bottom: 15px;
      }
    }
    span{
      opacity: 0.5;
      &.ellipsis{
        display: block;
        width: 200px;
        overflow: hidden;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        text-align: right;
      }
    }

    .amount{
      font-family: $RedHat;
      font-size: 18px;
      line-height: 24px;
      color: $violet_start;
      span{
        font-weight: 900;
        opacity: 1;
      }
    }
  }
  &_paymentTotal{
    padding: 20px 10px;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
  }

  &_buttons{
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 20px;
    .button{
      width: 140px;
      height: 40px;
      color: $white;
      background-color: $violet_start;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }
    .cancel{
      .button{
        background-color: $grey_disabled;
      }
    }

  }
}

.buttonAdd {
  background: transparent;
  border: none;

  &:focus {
    outline: none;
  }
}

.plusIcon {
  margin-top: -55px;
}

.customWidth {
  flex: 0 0 41%;
}

@media(max-width: 539px)  {
  .subscriptionsPackagesCard{
    max-width:280px
  }
  .customWidth {
    flex: 0 0 280px;
    box-sizing: content-box;
    width:280px
  }

  .pricePerMonth{
    .pricePerMonthValue{
      font-size: 15px;
    }
  }
  .selectSubscriptionButton{
    max-width:280px;
    min-width:280px;

    width:280px;
    .violetGradien{
      min-width:280px;
    }
  }
}