@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.lsrecordsList {
  padding: 0 20px;
  .filterBlock{
    .group.tags{
      flex-wrap: nowrap;
    }
  }

  h3 {
    margin-left: 3px !important;
  }

  .groupLsRecords {
    .itemBlock {
      border-radius: 4px;
      box-shadow: 0px 2px 8px rgba(70, 69, 95, 0.3);
      margin-bottom: 20px;
      margin-top: 10px;
      padding-bottom: 10px;
    }
  }

  .buttonGroup {
    width: 100%;
    padding-left: 0;
    padding-right: 0;

    button {
      width: 100%;

      span {
        font-size: 16px !important;
        line-height: 26px;
      }
    }
  }

  .filterBlock {
    background-color: initial !important;
    padding: 0px !important;

    .group {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .privateButton {
        margin-right: 0;
      }
    }

    .privateButton {
      // margin-right: 5px !important;

      button {
        background: #F4F4F7;
        border-radius: 2px !important;
        border: 1px solid #F4F4F7 !important;
        color: #9DA4B3 !important;
        padding: 4px 2px !important;
        height: auto !important;

        &:focus {
          outline: none;
        }
      }
    }

    .active {
      button {
        color: #fff !important;
      }
    }
  }
}

.addVideoBlock{
  width: -webkit-fill-available;
  margin: 20px 0;
}

@media (min-width: 960px) and (max-width: 1199px) {
  .dailyBlock {
    .lsrecordsList {
      .buttonGroup {
        button {
          width: 100% !important;
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 539px) {
  .lsrecordsList {
    .filterBlock{
      .group.tags{
        flex-wrap: wrap;
      }
    }
    button {
      padding: 4px 0px;

      span {
        font-size: 14px !important;
        line-height: 19px;
      }
    }
  }
}

@media (min-width: 540px) and (max-width: 719px) {

}

@media (min-width: 720px) and (max-width: 959px) {
  .filterBlock {
    .privateButton {
      margin-right: 5px !important;
    }
  }
}

@media (min-width: 960px) and (max-width: 1199px) {
  .filterBlock {
    .privateButton {
      margin-right: 5px !important;
    }
  }
  .dailyBlock {
    .titleBlock {
      .MuiButtonGroup-root {
        .MuiButtonBase-root {
          width: 160px; // !important;
        }
      }
    }
  }
}

@media (min-width: 1200px) {

}
