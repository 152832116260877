@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.emptyBlock {
  background: $white;
  border-radius: 5px;
  box-shadow: 0px 2px 5px $violet_start_1;
  margin: 20px 10px;
  padding: 20px;

  .empty {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    padding-top:30px;
    padding-bottom:30px;
    border: 1px dashed  $grey_blue;
    border-radius: 4px;
    stroke-dasharray: 5px;
    text-align: center;

    .title {
      color: $violet_start;
      font-family: $RedHat;
      font-size: 22px;
      font-style: normal;
      line-height: 29px;
      opacity: 0.5;
      text-align: center;
      margin-bottom: 10px;
      max-width:320px;
      margin-left: auto;
      margin-right: auto;
      font-weight: 700;
    }
    .green_title{
      color: $green_normal;
      font-family: $RedHat;
      font-size: 22px;
      font-style: normal;
      line-height: 29px;
      text-align: center;
      margin-bottom: 10px;
      margin-top: 20px;
      max-width:320px;
      margin-left: auto;
      margin-right: auto;
      font-weight: 400;
    }
    .desc {
      color: $violet_start;
      font-family: $RedHat;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      line-height: 19px;
      opacity: 0.5;
      text-align: center;
      max-width:320px;
      margin-left: auto;
      margin-right: auto;
    }

    .empty_img{
      width: 100%;
    }
  }
}
