@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.playlists_page{
  .title{
    color: #43425D;
    font-family: "Red Hat Display", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    margin: 25px 0 10px 23px;
  }
}

@media (min-width: 320px) and (max-width: 539px) {}

@media (min-width: 540px) and (max-width: 719px) {}

@media (min-width: 720px) and (max-width: 959px) {}

@media (min-width: 960px) and (max-width: 1199px) {}

@media (min-width: 1200px) {}
