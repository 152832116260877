@import '../../../const/colors.scss';
@import '../../../const/fonts.scss';

.contacts {
  align-items: center;
  background-size: cover;
  background: url("../../../res/img/questions.svg");
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-shadow: 0px 4px 4px $black25;
  width: 100%;

  .form {
    margin: 0;
    width: auto;
    margin-bottom: 16px;

    .subscribeForm {
      align-items: center;
      display: flex;
      justify-content: center;
      flex-direction: row;

      .email-input-wrapper {
        flex-grow: 1;
        padding-right: 20px;
      }
    }

    .title {
      // color: $violet_start;
      // display: block;
      // font-weight: 900;
      letter-spacing: 0.02em;
      font-family: $RedHatText;
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 22px;
      color: #FFFFFF;
    }

    .description-text {
      display: block;
      // color: $grey_violet_light;
      font-family: $RedHatText;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: #FFFFFF;
    }
  }
}

@media (min-width: 320px) and (max-width: 539px) {
  .contacts {
    // flex-direction: column;
    // margin: 44px 0 50px;
    // padding: 0 10px;
    width: 100%;

    img {
      display: block;
      margin: 0 -6px;
      width: 104%;
    }

    .form {
      margin-top: 21px;
      width: auto;

      .subscribeForm {
        flex-direction: column;
        width: 191px;
        margin: 0 auto;

        .email-input-wrapper {
          flex-grow: 1;
          padding-right: 0;
        }
      }

      .title {
        display: block;
        font-size: 20px;
        line-height: 22px;
        font-weight: bold;
      }

      .description-text {
        font-size: 16px;
        line-height: 21px;
        margin-top: 11px;
        margin-bottom: -22px;
        width: 302px;
      }

      .width100 {
        margin-top: 30px;
        width: 290px;
      }

      input {
        padding: 8px;
        width: 300px;
      }

      .width100 {
        margin-top: 14px;
        padding: 0;
        width: 100%;
      }
    }
  }
}

@media (min-width: 540px) and (max-width: 719px) {
  .contacts {
    width: 100%;
    align-items: center;
    justify-content: center;


    .form {
      margin-top: 57px;
      width: 100%;

      .subscribeForm {
        flex-direction: row;
        width: 191px;
        margin: 0 auto;

        margin-bottom: 35px;

      }

      .title {
        display: block;
        font-size: 30px;
        font-weight: bold;
        line-height: 22px;
        letter-spacing: -0.0241176em;
      }

      .description-text {
        font-size: 16px;
        line-height: 21px;
        margin-top: 29px;
        margin-bottom: 30px;
        width: 523px;
        margin-left: auto;
        margin-right: auto;
      }

      input {
        padding: 8px;
        width: 100%;
      }

      .width100 {
        margin-top: 0;
        width: 150px;
      }

      .button-wrapper {
        width: 191px;

        .width100 {
          padding: 0;
          width: 191px;
        }
      }
    }
  }
}

@media (min-width: 720px) and (max-width: 959px) {
  .contacts {
    // margin: 100px auto;
    // padding: 0 10px;
    background-size: cover;
    width: 100%;

    img {
      display: block;
      width: 295px;
      margin: -5px -7px;
    }

    .form {
      margin-left: 0px;
      margin-top: 56px;
      width: 100%;


      .title {
        font-size: 30px;
        line-height: 22px;
        width: 100%;
      }

      .description-text {
        font-size: 16px;
        line-height: 21px;
        margin: 23px auto 29px auto;
        width: 526px;
      }

      input {
        padding: 8px;
        width: 100%;
      }

      .button-wrapper {
        width: 189px;

        .width100 {
          padding: 0;
          width: 189px;
        }
      }
    }
  }
}

@media (min-width: 960px) and (max-width: 1199px) {
  .contacts {
    // margin: 90px auto;
    // padding: 0 10px;
    // width: 960px;
    background-size: cover;

    img {
      width: 400px;
      margin: -1px -9px;
    }

    .form {
      width: 509px;
      margin-top: 54px;
      margin-left: 45px;
      text-align: center;
      margin-bottom: 51px;

      .subscribeForm {
        padding: 30px 0;
        width: 191px;
        margin: 0 auto;
      }

      .title {
        font-size: 31px;
        line-height: 22px;
        width: 100%;
      }

      .description-text {
        padding-top: 28px;
        font-size: 16px;
        line-height: 21px;
      }

      input {
        padding: 8px;
      }

      .button-wrapper {
        .width100 {
          padding: 0;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .contacts {
    // width: 1000px;
    // margin: 105px auto;

    img {
      margin: -16px -9px;
    }

    .form {
      width: 524px;
      margin-top: 57px;
      margin-left: 45px;
      text-align: center;
      margin-bottom: 51px;

      .subscribeForm {
        padding-top: 30px;
        width: 191px;
        margin: 0 auto;
      }

      .title {
        font-size: 30px;
        line-height: 22px;
        width: 100%;
      }

      .description-text {
        padding-top: 22px;
        font-size: 16px;
        line-height: 21px;
      }

      .button-wrapper {
        .width100 {
          padding: 0;
        }
      }
    }
  }
}