@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.follow {
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(67, 66, 93, 0.1);
  // margin: 20px 10px;
  padding: 10px 20px;


  .connectButtons{
    .small{
      margin-left: 0px !important; 
    }
  }
  h2 {
    font-family: $RedHat;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: #43425D;
    text-align: center;
  }

  .rightBox-title {
    margin-top: 0px !important;
  }
}

@media (min-width: 1200px) {
  .rightBlock .rightBox {
    width: 235px;
  }
}

@media (min-width: 540px) and (max-width: 719px) {
  .follow {
    padding: 7px 20px 13px;
    h2 {
      width: 100%;
      text-align: left;
      padding-left: 10px;
      letter-spacing: unset;
      padding-bottom: 3px;
      font-weight: bold;
    }

    .personInfo {
      padding-top: 12px;
      .textInfo{

        .title{
          font-weight: bold;
        }
        .button-wrapper{
          margin-top: 7px;
        }
      }
    }
  }
}
