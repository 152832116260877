@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.groupDetailsBlock {


  .buyMediaGroups{
    left: 18px;
    top: 48px;
  }
  .detailsBlock {
    align-items: baseline;
    display: flex;
    flex-direction: column;

    &_name {
      color: black;
      font-family: $RedHat;
      font-size: 28px;
      font-style: normal;
      font-weight: bold;
      line-height: 100%;
      margin-bottom: 10px;
    }

    &_owner{
      margin-bottom: 10px;
    }
    &_mediaInfo{
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
    &_socialBtns{
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .detailsBlock_socialBtns{
      .button-wrapper{
        width: auto;
        margin-top: 0;
        margin-left: 10px;
        position: relative;
        display: flex;
        align-items: center;
        button{
          padding: 10px;
          color: $grey_normal;
          font-size: 12px;
          line-height: 16px;
          width: initial;
          &:focus,
          &:active{
            outline: none;
          }
        }
      }
    }

    > div {
      padding: 0 20px;
      &:first-child{
        padding: 20px 20px 0;
      }
      &:last-child{
        padding: 0 20px 20px;
      }
    }
    .content_description_wrapper{
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: baseline;
      .toggleSaveBtn{
        white-space: nowrap;
        button{
          &:focus,
          &:active{
            outline: none;
          }
        }

      }
    }
    .content_description{
      padding: 10px 0 20px;
      font-size: 18px;
      font-family: $RedHat;
    }

    .detailsDateTime {
      display: flex;

      .detailsDate {
        align-items: flex-start;
        color: #7E7D8F;
        display: flex;
        font-family: $RedHat;
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        line-height: 19px;
      }

      .detailsTime {
        align-items: flex-start;
        color: #7E7D8F;
        display: flex;
        font-family: $RedHat;
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        line-height: 19px;
        margin-left: 30px;

        div {
          margin-bottom: 5px;
        }
      }
    }

    .groupTileTag {
      display: flex;
      align-items: center;

      .groupTag {
        padding: 4px;
        background: #43425D;
        border-radius: 4px;
        margin-left: 20px;
        font-family: $RedHat;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #FFFFFF;
      }
    }

    .detailsPrice {
      display: flex;

      .detailsMeetings {
        font-family: $RedHat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: flex-end;
        color: #7E7D8F;

        span {
          font-weight: bold;
        }
      }

      .detailsProce {
        margin-left: 30px;
        font-family: $RedHat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        color: #43425D;

        span {
          font-weight: bold;
        }
      }
    }

    .detailsDesc {
      color: #43425D;
      font-family: $RedHat;
      font-size: 18px;
      font-style: normal;
      font-weight: normal;
      line-height: 24px;
      margin: 19px 0 0; // 30px
      text-align: justify;
    }

    .unsubscribeButton {
      button {
        background: linear-gradient(0.31deg, #FF2D55 1.96%, #EB6081 102.09%);
        box-shadow: 0px 2px 5px rgba(255, 45, 85, 0.5);
        border-radius: 4px;
        color: #FFFFFF;
        border: 0;
      }
    }

    .button-wrapper {
      width: 100%;
      margin-top: 30px;

      button {
        font-family: $RedHat;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        width: 100%;
        text-transform: uppercase;
        padding: 13px 0;
        text-align: center;
      }
    }

    .detailsCheckList {
      margin-top: 30px;
      width: 100%;

      .detailCheckItem {
        background: #FFFFFF;
        box-shadow: 1px 1px 4px rgba(70, 69, 95, 0.3);
        border-radius: 4px;
        padding: 5px 10px;
        margin-bottom: 8px;
        font-family: $RedHat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        color: #43425D;
      }
    }

    .refundButton {
      margin-top: 30px;

      button {
        background: #FFFFFF;
        border: 1px solid #FF2D55;
        box-sizing: border-box;
        border-radius: 4px;
        font-family: $RedHat;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #FF2D55;
      }
    }

    .refundBlock {
      margin: -20px;
      background: #000000;
      // border-radius: 4px;
      padding: 50px 95px;
      width: 490px;
      overflow-x: hidden;

      .refundTitle {
        font-family: $RedHat;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 100%;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 50px;
      }

      .labelText {
        font-family: $RedHatText;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 10px;
      }

      .amountInput,
      .reasoneInput {
        width: 100%;
        margin-bottom: 20px;

        .MuiInput-underline {

          &::before {
            border: 0 !important;
          }

          &::after {
            border: 0 !important;
          }
        }

        input {
          background: #202020;
          border-radius: 4px;
          padding: 10px;
          font-family: $RedHatText;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          color: rgba(233, 233, 240, 0.5);
        }
      }

      .refundButtons {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        width: 100%;

        .refundCancel {
          background: #000000;
          border: 1px solid #FFFFFF;
          box-sizing: border-box;
          border-radius: 2px;
          width: 140px;

          button {
            font-family: $RedHat;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #FFFFFF;
            padding: 0;
            line-height: 38px;
          }
        }

        .refuntComplate {
          background: #FFFFFF;
          border-radius: 2px;
          width: 140px;

          button {
            font-family: $RedHat;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #000000;
            padding: 0;
            line-height: 42px;
          }
        }
      }
    }
  }
  .livestream_records{
    width: 100%;
    margin-top: 10px;
  }

  .bySubscriptions{
    background-color: $rose;
    width: 100%;
    padding: 0;
    margin-top: 20px;
    p{
      font-size: 22px;
      line-height: 24px;
      text-align: center;
      color: $white;
      margin: 15px 60px
    }
  }

  .detailsAttends {
    margin: 20px 0;
    background: #FFFFFF;
    border: 1px solid #F4F4F7;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: $RedHat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #43425D;
    padding: 22px 20px;
    width: 100%;
    display: flex;
    align-items: center;

    span {
      font-weight: bold;
    }
    
    .titleAttendees {
      margin-right: auto;
    }

    .actionAttendees {
      font-family: $RedHat;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      color: #598E7E;
      cursor: pointer;
    }
  }
}

.groupActionsBlock {
  right: 5%;
  width: 110%;
  position: relative;
  margin-top: 25px;
}

@media (min-width: 320px) and (max-width: 539px) {
  .unsubscribeButton {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
}
