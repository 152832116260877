@import '../../../const/colors.scss';
@import '../../../const/fonts.scss';

.buttonGroup {
  padding: 30px 23px 18px;

  .groupButton {
    background: $white;
    border: 1px solid $violet_start;
    box-sizing: border-box;

    .buttonText {
      color: $violet_start;
      font-family: $RedHat;
      font-size: 20px;
      font-style: normal;
      font-weight: normal;
      line-height: 26px;
      text-transform: initial;
    }
  }

  .activeButton {
    background: $violet_start;
    border: 1px solid $violet_start;
    box-sizing: border-box;

    &:hover {
      background: $violet_start;
    }

    .activeButtonText {
      color: $white;
      font-family: $RedHat;
      font-size: 20px;
      font-style: normal;
      font-weight: normal;
      line-height: 26px;
      text-transform: initial;
    }
  }

  button {
    width: 150px;
    padding: 4px 15px;
  }
}

.transactionsBlock {
  .filter {
    background: $grey_white;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 10px 22px 11px;
    flex-wrap: wrap;

    .paymentDatePicker {
      .withIcon {
        color: #B1B1BF;
        font-family: $RedHat;
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
        line-height: 13px;
        margin-bottom: 0;

        input {
          padding-top: 8px;
          padding-bottom: 5px;
        }

        button {
          margin-right: -8px;
          margin-top: 6px;
          padding: 0;
        }
      }
    }
  }
}

.paymentItem {
  background: $white;
  border-radius: 4px;
  box-shadow: 0px 2px 8px $violet_dark_3;
  margin: 10px 22px;
  position: relative;

  .paymentData {
    padding: 19px 17px 21px;

    .paymentTitle {
      color: $violet_start;
      font-family: $RedHat;
      font-size: 16px;
      font-style: normal;
      font-weight: normal;
      line-height: 21px;
      margin-bottom: 9px;
    }

    .paymentSubTitle {
      color: $grey_normal;
      font-family: $RedHat;
      font-size: 10px;
      font-style: normal;
      font-weight: normal;
      line-height: 13px;
      opacity: 0.5;
    }
  }

  .paymentFooter {
    background: $grey_light;
    color: $green_normal;
    display: flex;
    font-family: $RedHat;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    justify-content: space-between;
    line-height: 19px;
    padding: 16px 20px 16px 16px;

    .paymentPrice {
      font-weight: bold;
    }
  }

  .refundButton {
    position: absolute;
    right: 10px;
    top: 10px;
   
    button {
      color: $violet_start;
      font-family: $RedHatText;
      font-size: 12px;
      font-style: normal;
      font-weight: normal;
      line-height: 16px;
      padding: 3px 8px;
      text-align: center;
      outline: none;
      border: 1px solid $grey_white;
    }

    .disactive {
      border: 1px solid #E9E9F0;
      color: #E9E9F0;
      background: #FFFFFF;
      box-shadow: none;
    }
  }
}

.purchases {
  cursor: pointer;
  
  .refundButton {
    button {
      border: 1px solid #E9E9F0;
    }
  }
}

.totalBlock {
  background: $white;
  border-radius: 4px;
  box-shadow: 0px 2px 8px $violet_dark_3;
  margin: 20px;
  padding: 20px;

  .totalTitle {
    color: $violet_start;
    font-family: $RedHat;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    margin-bottom: 20px;

    span {
      font-weight: bold;
    }
  }

  .totalDesc {
    color: $violet_start;
    font-family: $RedHat;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    margin-bottom: 20px;
    opacity: 0.5;
  }

  .withdrawnBlock {
    color: $green_normal;
    display: flex;
    font-family: $RedHat;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    margin-top: 20px;
    
    span {
      font-weight: bold;
    }

    .withdrawButton {
      margin-left: auto;
      width: 63px;

      .small {
        width: 100%;
      }
    }
  }
}

.transactionsDetails {
  border: 1px solid $grey_light;
  box-sizing: border-box;
  border-radius: 4px !important;
  margin: 10px 20px !important;
  box-shadow: none !important;

  &::before {
    height: 0 !important;
  }

  &.Mui-expanded {
    background: $grey_light;
  }

  .transactionsSummary {
    display: flex;
    font-family: $RedHat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: $violet_start;
    align-items: center;

    span {
      margin-left: auto;
      line-height: 19px;
      opacity: 0.5;
    }
  }

  .transactionsData {
    display: flex;
    flex-direction: column;
    padding-bottom: 1px;

    .payBlock {
      padding: 4px 37px 4px 0;
    
      .payTytle {
        color: $violet_start;
        display: flex;
        font-family: $RedHat;
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        line-height: 19px;

        span {
          margin-left: auto;
        }

        .payName {
          margin-left: 0;
          text-transform: capitalize;
        }

        &.activePay {
          color: $green_normal;
        }
      }

      .paySubData {
        color: $violet_start;
        display: flex;
        font-family: $RedHat;
        font-size: 10px;
        font-style: normal;
        font-weight: normal;
        line-height: 13px;
        opacity: 0.5;

        span {
          margin-left: auto;
        }
      }
    }
  }
}

.refundBlock {
  padding: 60px 85px 50px;

  .dialogContent {
    height: 343px;
  }

  .refundTitle {
    color: $violet_start;
    font-family: $RedHat;
    font-size: 28px;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.02em;
    line-height: 100%;
    margin-bottom: 32px;
    text-align: center;
  }
  
  .refundText {
    width: 100%;
    margin: 0;

    .refundFieldName {
      color: $violet_start;
      font-family: $RedHat;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      line-height: 19px;
      opacity: 0.5;
    }

    .refundTextarea {
      background: #F4F4F7;
      border-radius: 4px;
      border: 0;
      color: $violet_start;
      font-family: $RedHat;
      font-size: 16px;
      font-style: normal;
      font-weight: normal;
      line-height: 21px;
      margin-bottom: 30px;
      width: 100%;
      resize: none;

      .MuiInput-root {
        padding: 14px 10px;

        &:before {
          border: 0;
        }
      }
    }
  }
}

.subscribedCardData{
  &_ownerData{
    display: flex;
    flex-direction: row;
    margin: 30px 86px 0;

    & + .subscriptionsPackagesCardList{
      padding: 20px;
    }
  }
  &_ownerAvatar{
    margin-right: 10px;
    .MuiAvatar-root{
      width: 50px;
      height: 50px;
      font-size: 0.9rem;
    }
  }
  &_ownerWrapper{
    font-family: $RedHat;
    font-size: 14px;
    line-height: 19px;
    color: $grey_disabled
  }
  &_ownerName{
    font-weight: bold;
    font-size: 22px;
    line-height: 29px;
    color: $violet_start
  }
}

@media (min-width: 320px) and (max-width: 539px) {
  .refundBlock {
    padding: 60px 10px 50px;
  }
}
