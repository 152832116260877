@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.header {
  align-items: center;
  background: $white;
  box-shadow: 0px 4px 4px $black25;
  display: flex;
  height: 80px;
  justify-content: space-between;
  padding: 0 10px;

  .logoMain {
    z-index: 2;
  }

  &>div {
    z-index: 1;
  }

  &>img {
    z-index: 2;
  }

  .buttons {
    margin-left: auto;
    display: flex;
    align-items: center;

    .title {
      color: $violet_start;

      &:hover {
        text-decoration: none;
      }
    }

    .buttonSpace {
      margin-right: 20px;
      margin-bottom: 0;
    }

    .avatarActionBlock {
      margin-left: 23px;

      &:focus {
        outline: none;
      }
    }

    .headerAvatar {
      height: 50px;
      width: 50px;
    }

    .userName {
      color: $white;
      display: flex;
      font-family: $RedHatText;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      padding-left: 15px;
      min-width: 100px;
      text-align: left;
    }
  }

  &.firstLogin{
    .buttons{
      width: unset !important;
      margin-left: auto !important;
    }   
  }
}

.header.logined {
  background: $violet_start;

  .bm-burger-bars {
    background-color: $white;
  }

  .community {
    align-items: center;
    align-items: flex-end;
    color: #A8A7BC;
    display: flex;
    font-family: $RedHat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    margin-left: 60px;
    width: 126px;
    svg{
      min-width: 16px;
      width: 16px;
    }

    span {
      margin-left: 10px;
      border-bottom: 1px dashed;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.menu-item {
  border: 1px solid $violet_dark;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;


  &.white {
    color: $violet_dark;
  }

  &.black {
    color: $white;
    background: linear-gradient(0.27deg, $violet_start 1.96%, $violet_end 102.09%);
    box-shadow: 0px 2px 5px $violet_dark_3;
    border-radius: 4px;
  }
}

.headroom--unfixed {
  position: relative;
  transform: none !important;
}

.headroom {
  z-index: 11 !important;
}

.logoButton {
  background: initial;
  border: 0;

  &:focus {
    outline: none;
  }
}

.communityMenu {
  background: #FFFFFF;
  box-shadow: 0px 2px 5px rgba(67, 66, 93, 0.1) !important;
  border-radius: 5px !important;
  width: 150px !important;

  ul {
    padding: 0 !important;
  }

  .communityItem {
    font-family: $RedHat !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #43425D !important;
    padding: 5px 21px !important;

    &:hover {
      background: #666585 !important;
      color: #FFFFFF !important;
    }
  }
}

@media (min-width: 320px) and (max-width: 539px) {
  .bm-burger-button {
    position: absolute;
    width: 24px;
    height: 23px;
    right: 12px;
    top: 28px;
    display: block;
  }

  .burger {
    padding: 90px auto;
    display: flex;
    justify-content: center;
    background-color: $white;

    .community {
      display: flex !important;
      margin: 10px 20px;
      // width: 100% !important;
      clear: both;
      width: 220px !important;
    }
    .communityMobileBlock{
      .community{
        margin: {
          left: 0;
          right: 0;
          bottom: 0;
        };
      }
    }

    .bm-item {
      float: left;
      clear: both;
    }

    .button {
      width: 300px;
      margin: 14px 10px;
      padding: 8px;
      
    }
  }

  .header {
    height: 80px;
    padding: 0 10px;


    .logoMain{
      z-index: 1;
    }
    .buttons {
      display: none;
    }

    .menu-item {
      font-size: 18px;
      line-height: 34px;
      margin-bottom: 30px;
      text-align: center;
      height: 40px;
    }

    nav {
      width: 280px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 100px;

      .buttonSpace {
        margin-bottom: 0;
      } 
    }
  }

  .header.logined {
    .burger {
      width: 320px !important;
      justify-content: initial;
      // padding-top: 75px;

      .bm-item-list {
        margin: 0;

        nav {
          margin: 0 20px;

          .noBottomSpace {
            margin: 0;
          }
        }
      }

      .button {
        margin: 0;
        width: 130px;
      }
    }

    .userBlock,
    .communityMobileBlock {
      display: flex !important;
      justify-content: center;
      align-items: center;
      margin: 7px 0 30px 20px;
      z-index: 1;

      &:focus {
        outline: none;
      }

      .userName {
        font-family: $RedHatText;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        display: flex;
        align-items: flex-end;
        color: $violet_start;
        margin-left: 15px;
      }
    }
    .communityMobileBlock{
      margin-bottom: 10px;
    }

    .community {
      display: none;
    }
  }
}

@media (min-width: 540px) and (max-width: 719px) {
  .bm-burger-button {
    position: absolute;
    width: 24px;
    height: 23px;
    right: 12px;
    top: 28px;
    display: block;
  }
  

  .burger {
    padding: 90px auto;
    display: flex;
    justify-content: center;
    background-color: $white;

    .bm-item {
      float: left;
      clear: both;
    }

    .button {
      width: 300px;
      margin: 14px 10px;
    }

    nav {
      width: 320px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 110px;

      .buttonSpace {
        margin-bottom: 0;
      }
    }
  }

  .header {
    height: 80px;
    padding: 0 10px;

    .logoMain{
      z-index: 1;
    }

    .buttons {
      display: none;
    }

    .menu-item {
      font-size: 18px;
      line-height: 34px;
      margin-bottom: 30px;
      text-align: center;
      height: 40px;
    }
  }

  .header.logined {
    .burger {
      width: 350px !important;
      justify-content: initial;

      .community {
        display: flex !important;
        margin: 10px 20px;
        // width: 100% !important;
        clear: both;
        width: 220px;
      }

      .bm-item-list {
        margin: 0;

        nav {
          margin: 0 20px;

          .noBottomSpace {
            margin: 0;
          }
        }
      }

      .button {
        margin: 0;
        width: 130px;
      }
    }

    .userBlock,
    .communityMobileBlock {
      display: flex !important;
      justify-content: center;
      align-items: center;
      margin: 7px 0 30px 20px;
      z-index: 1;

      &:focus {
        outline: none;
      }

      .userName {
        font-family: $RedHatText;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        display: flex;
        align-items: flex-end;
        color: $violet_start;
        margin-left: 15px;
      }
    }

    .community {
      display: none;
    }
  }
}

@media (min-width: 720px) and (max-width: 959px) {
  .bm-burger-button {
    position: absolute;
    width: 24px;
    height: 23px;
    right: 12px;
    top: 28px;
    display: block;
  }

  .burger {
    padding: 90px auto;
    display: flex;
    justify-content: center;
    background-color: $white;

    .bm-item {
      float: left;
      clear: both;
    }

    .button {
      width: 340px;
      margin: 14px 10px;
    }

    nav {
      width: 360px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 140px;

      .buttonSpace {
        margin-bottom: 0;
      }
    }
  }

  .header {
    height: 80px;
    padding: 0 10px;

    .menu-item {
      font-size: 18px;
      line-height: 34px;
      margin-bottom: 30px;
      text-align: center;
      height: 40px;
    }

    .buttons {
      display: none;
      // margin-right: 70px;
    }
  }

  .header.logined {

    .community {
      display: flex !important;
      margin: 10px 20px;
      // width: 100% !important;
      clear: both;
      width: 220px;
      
      span{
        margin-left: 9px;
      };
    }
    .communityMobileBlock{
      .community{
        margin: {
          left: 0;
          right: 0;
        };
      }
    }

    .burger {
      width: 350px !important;
      justify-content: initial;

      .bm-item-list {
        margin: 0;

        nav {
          margin: 0 20px;

          .noBottomSpace {
            margin: 0;
          }
        }
      }

      .button {
        margin: 0;
        width: 130px;
      }
    }

    .userBlock,
    .communityMobileBlock{
      display: flex !important;
      justify-content: center;
      align-items: center;
      margin: 30px 0 30px 20px;
      z-index: 1;

      &:focus {
        outline: none;
      }

      .userName {
        font-family: $RedHatText;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        display: flex;
        align-items: flex-end;
        color: $violet_start;
        margin-left: 15px;
      }
    }
    .communityMobileBlock{
      margin-bottom: 10px;
    }
  }
}

@media (min-width: 960px) and (max-width: 1199px) {
  .bm-burger-button {
    display: none;
  }

  .header {
    height: 80px;
    padding: 0 10px;

    .buttons {

      .registerButton {
        button {
          padding: 8px 42px;
        }
      }

      .title {
        padding-right: 30px;
      }
    }
  }

  .header.logined {
    .buttons {
      width: 100%;
      margin-left: 40px;

      .MuiButtonBase-root.avatarActionBlock {
        margin-left: 9px;
        .userName{
          width: 77px;
          min-width: 77px;
        }
      }
    }
  }

}

@media (min-width: 1200px) {
  .bm-burger-button {
    display: none;
  }

  .header {
    padding: 0 100px;

    .title {
      font-size: 18px;
      line-height: 22px;
      color: $violet_start;
      padding-right: 30px;
    }
  }

  .header.logined {
    .buttons {
      width: 100%;
      margin-left: 60px;
    }
    
    .withCommunity {
      // width: 560px;
    }

    .community {
      align-items: center;
      align-items: flex-end;
      color: #A8A7BC;
      display: flex;
      font-family: $RedHat;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      margin-left: 60px;

      span {
        margin-left: 10px;
        border-bottom: 1px dashed;
      }
    }
  }

  .communityMenu {
    background: #FFFFFF;
    box-shadow: 0px 2px 5px rgba(67, 66, 93, 0.1) !important;
    border-radius: 5px !important;
    width: 150px !important;

    ul {
      padding: 0 !important;
    }

    .communityItem {
      font-family: $RedHat !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 19px !important;
      color: #43425D !important;
      padding: 5px 21px !important;

      &:hover {
        background: #666585 !important;
        color: #FFFFFF !important;
      }
    }
  }
}


// burger

// .bm-burger-button {
//   display: none;
// }

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: $grey_violet;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: $red_dark;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: $grey_blue_light;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 1000vh !important;
  top: 0;
  max-width: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: $white;
  padding: 0;
  font-size: 1.15em;
  height: 100px;
  box-sizing: border-box;
  overflow: initial !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: $grey_violet;
}

/* Wrapper for item list */
.bm-item-list {
  color: $grey_yellow;
  margin-top: 90px;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: $wite-red;
  background: $black7 !important;
}

@media (min-width: 720px) and (max-width: 959px) {
  .bm-cross-button {
    top: 18px !important;
    right: 18px !important;
  }
}

@media (min-width: 540px) and (max-width: 719px) {
  .bm-cross-button {
    top: 18px !important;
    right: 18px !important;
  }
}

@media (min-width: 320px) and (max-width: 539px) {
  .bm-cross-button {
    top: 9px !important;
    right: 11px !important;
  }
}