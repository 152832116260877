@import '../../const/colors.scss';
@import '../../const/fonts.scss';


.notifications {
  margin-bottom: 20px;
  &_empty{
    margin: 20px 20px 30px;
    font-size: 20px;
    line-height: 20px;
    color: $grey_normal;

  }
  
  .notificationItem {
    padding: 15px 25px;

    .preview{
      width: 75px;
      min-width: 75px;
      height: 50px;
      object-fit: cover;
    }
    p {
      display: flex;
      align-items: start;
      justify-content: space-between;
    }
    .content {
      margin-left: 10px;
      width: 100%;
    }
    .type {
      font-family: $RedHatText;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #666585;
    }
    .title {
      font-family: $RedHat;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      color: #43425D;
    }
    .reason{
      font-family: $RedHatText;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #7E7D8F;
    }
    .time {
      font-family: $RedHatText;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 13px;
      color: #7E7D8F;
      opacity: 0.5;
      text-align: left;
    }
    .owner {
      font-family: $RedHatText;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #7E7D8F;
    }
  }
  .blockDivider {
    margin: 0 20px;
  }
}
