body {
  margin: 0;
  font-family: 'Red Hat Text', sans-serif;
  font-style: normal;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

@media (max-width: 319px) {
  body {
    width: 320px;
    overflow: auto;
  }
}
