@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.searchHeader {
  .MuiPaper-root {
    background: $violet_grey;
    border-radius: 5px;
    display: none;
    flex-direction: row;
    height: 30px;
    width: 405px;
    padding: 5px;
  }

  .inputElem {
    font-family: $RedHatText;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    align-items: flex-end;
    color: $white;
    width: 100%;
    padding-left: 11px;
  }
}

@media (min-width: 720px) {
  .searchHeader {
    margin-right: 0;
  }
}

@media (min-width: 960px) {
  .searchHeader {
    margin-right: auto;

    .root {
      display: block;
    }

    .badgeButton {
      display: none;
    }

    .MuiPaper-root {
      width: 325px;
      display: flex;
    }

    .iconButton {
      padding: 6px;
    }
  }
}

@media (min-width: 1200px) {
  .searchHeader {
    margin-right: auto;

    .root {
      display: block;
    }

    .badgeButton {
      display: none;
    }

    .MuiPaper-root {
      width: 405px;
      display: flex;
    }

    .iconButton {
      padding: 6px;
    }
  }
}
