@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.inviteMembersBlock {
  .title {
    font-family: $RedHat;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: #4E519F;
    margin-bottom: 20px;
  }

  .membersList {
    display: flex;
    flex-wrap: wrap;
    max-width: 320px;
    max-height: 344px;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: block;
      width:5px;
      margin: 5px 10px 5px 0;
    }
    &::-webkit-scrollbar-thumb {
        width: 5px;
        background: #bdbdbd;
        border-radius: 2px;
    }
    .addBlock {
      cursor: pointer;
      margin-right: 20px;
      
      .addButton {
        background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
        border: 1px dashed #B1B1BF;
        box-sizing: border-box;
        height: 80px;
        width: 80px;
        border-radius: 50%;
        padding: 23px;
      }

      .addTitle {
        font-family: $RedHat;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #B1B1BF;
        margin-top: 8px;
      }
    }

    .imviteUser {
      position: relative;
      margin-right: 20px;
      max-width: 80px;


      .clearButton {
        position: absolute;
        top: 0;
        right: 0;
        width: 24px;
        height: 24px;
        color: #43425D;
        z-index: 1;
        border: 0;
        padding: 0;
        background: none;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .userAvatar {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }

      .name {
        font-family: $RedHat;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        word-break: break-all;
        color: #43425D;
      }
    }
  }
  
}

.inviteSerchBlock {
  padding: 50px 85px 60px;

  .title {
    font-family: $RedHat;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 100%;
    color: #43425D;
    text-align: center;
    margin-bottom: 30px;
  }

  .membersList {
    margin-bottom: 30px;

    .user {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 10px;
      margin-bottom: 1px;
      cursor: pointer;

      .avatar {
        margin-right: 8px;
      }

      .name {
        font-family: $RedHat;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: #43425D;
        margin-right: auto;
      }

      .icon {
        color: #DADADA;
        width: 24px;
        height: 24px;
        font-size: 24px;
      }

      &.active {
        background: #43425D;

        .name {
          color: #FFFFFF;
        }

        .icon {
          color: #FFFFFF;
        }
      }
    }
  }

  .description {
    font-family: $RedHat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #43425D;

    span {
      font-weight: bold;
    }
  }

  .clearButton {
    border: 0;
    background: none;
  }
}

.inputBlock {
  border: 1px solid rgba(0, 0, 0, 0.27);
  border-radius: 4px;
  padding: 14px;
  display: flex;

  input {
    background: initial;
    border: 0;
    max-width: 90%;
    flex-grow: 1;

    &:focus,
    &:hover {
      border: 0;
      outline: -webkit-focus-ring-color auto 0px;
    }
  }

  &:focus,
  &:hover {
    border: 1px solid rgba(0, 0, 0, 1);
  }
}

.memberListScroll {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  position: inherit !important;
  flex-direction: column !important;
  max-height: 300px !important;
  width: 100% !important;
  justify-content: space-between !important;
}
.dailyBlock .listBlockUpcoming .listBlockUpcomingWrapper.memberListScroll::-webkit-scrollbar {
  display: block;
  height: 5px;
  margin: 5px 10px 5px 0;
  width: 5px !important;
}
