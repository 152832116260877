@import '../../../src/const/colors.scss';

.footer-app-store {
	margin-left: auto;
}
.footer-google-play {
	padding-left: 18px;
}

.home-app-store {
	border-radius: 5px;
	box-sizing: border-box;
	margin-left: auto;
	margin-top: 33px;
}

.home-google-play {
	border-radius: 5px;
	box-sizing: border-box;
	margin-left: 14px;
	margin-top: 33px;
}

@media (min-width: 320px) and (max-width: 539px) {
	.home-app-store,
	.home-google-play {
		margin-top: 27px;
	}
}

@media (min-width: 576px) {}

@media (min-width: 768px) {}

@media (min-width: 960px) and (max-width : 1199px) {}

@media (min-width: 1200px) {}
