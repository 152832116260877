@import '../../../const/colors.scss';
@import '../../../const/fonts.scss';


.personContent{
  .listBlock{
    @media (max-width: 720px){
      grid-template-columns: 1fr !important;
    }
    @media (min-width: 720px) and (max-width: 1400px){
      grid-template-columns: 1fr 1fr !important;
    }

    @media (min-width: 1400px) and (max-width: 1800px){
      grid-template-columns: 1fr 1fr 1fr !important;
    }

    @media (min-width: 1800px){
      grid-template-columns: 1fr 1fr 1fr 1fr !important;
    }
  }
}

.dailyBlock {
  background: $white;
  border-radius: 5px;
  box-shadow: 0px 2px 5px $violet_start_1;
  margin: 20px 10px;
  overflow-y: auto;
  padding: {
    bottom: 10px;
  };

  .titleBlock {
    margin: 30px 20px;

    h3 {
      color: $violet_start;
      font-family: $RedHat;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      margin: 25px 0 10px 23px;
    }

    .MuiButtonGroup-root {
      .buttonText,
      .activeButtonText{
        line-height: 0.9;
      }
      .MuiButtonBase-root {
        height: 35px;
        min-height: 35px;
      }

      .activeButton,
      .activeButton:hover {
        background: $violet_start;
      }

      .buttonText {
        color: $violet_start;
        font-family: $RedHat;
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
      }

      .activeButtonText {
        color: $white;
        font-family: $RedHat;
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
      }
    }
  }

  .workshops {
    flex-direction: column;
    margin: 0;

    .filterBlock {
      background: $grey_white;
      padding: 20px; // 16px 23px;
      width: 100%;
      margin: 0;
      text-align: start;

      .group {
        padding-bottom: 6px;
        display: flex;
        flex-wrap: wrap;
      }

      .privateButton {
        margin-right: 9px;

        button {
          border: 1px solid $violet_start;
          box-sizing: border-box;
          border-radius: 4px;
          width: 100%;
          height: 25px;
          padding: 0;
          margin-right: 8px;
          margin-bottom: 10px;
          margin-top: 10px;
          font-family: $RedHat;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          color: $violet_start;
          text-align: center;
        }
      }

      .wdayButton {
        button {
          width: 30px;
          height: 26px;
          border: 1px solid $violet_start;
          box-sizing: border-box;
          border-radius: 4px;
          margin-top: 10px;
          margin-right: 10px;
          margin-bottom: 10px;
          font-family: $RedHat;
          font-style: normal;
          font-weight: normal;

          font-size: 14px;
          line-height: 19px;
          color: $violet_start;
        }
      }

      .datePickerBlock {
        button {
          width: 114px;
          height: 25px;
          background: $violet_start;
          border-radius: 4px;
          margin-top: 9px;
          font-family: $RedHat;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          color: $white;
          padding: 0;

          .time-picker-time-label {
            width: 68px;
            display: inline-block;
            font-variant-numeric: tabular-nums;
          }

          img {
            margin-top: -3px;
          }
        }
      }

      .active {
        button {
          background: $violet_start;
          font-weight: bold;
          color: $white;
        }
      }
    }
  }

  .listBlock{
    display: grid !important;
    @media (max-width: 720px){
      grid-template-columns: 1fr;
    }
    @media (min-width: 720px) and (max-width: 1200px){
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1200px) and (max-width: 1600px){
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (min-width: 1600px){
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  

    &.emptyListBlock{
      display: unset !important;
    }

    .itemBlock {
      flex: 1 0 21%;
    }
    .groupLsRecords{
      flex: 1 0 21%;
    }
    .itemBlock:last-child{
      //margin-bottom: 0;
    }
    padding-bottom: 20px;
    overflow: hidden;
    .emptyBlock {
      box-shadow: none;
      width: 100%;
    }

    .groupLsRecords {
      .itemBlock {
        border-radius: 4px;
        box-shadow: 0px 2px 8px rgba(70, 69, 95, 0.3);
        margin-bottom: 20px;
        margin-top: 10px;
        padding-bottom: 10px;
        position: relative;
      }
    }
  }
  .listBlockUpcoming{
    position: relative;
    width: 100%;
    padding: 0 20px;
    overflow: hidden;
    .listBlockUpcomingWrapper{
      overflow-x: scroll;
      overflow-y: hidden;
      display: flex;
      //grid-column-gap: 40px;
      width: calc(100% - 40px);
      bottom: 0;
      top: 0;
      align-items: start;
      height: 100%;
      position: absolute;
      padding: 0 2px;
      &::-webkit-scrollbar{
        display: block;
        height: 5px;
        margin: 5px 10px 5px 0;
      }
      
      &::-webkit-scrollbar-thumb {
        height: 5px;
        background: #bdbdbd;
        border-radius: 2px;
      }
      
      .itemBlock{
        margin-left: 20px;
        margin-right: 20px;
        &:first-child{
          margin-left: 0px !important;
        }
        &:last-child{
          margin-right: 0px !important;
        }
      }
    }
  }
  .moreButtonBlock {
    padding-bottom: 20px;
    text-align: center;
    margin-top: 0;

    .moreButton {
      width: 318px;
      height: 60px;
    }
  }
}
.moreButtonBlock{
  button:focus{
    outline: unset;
  }
  button:active{
    outline: 5px auto -webkit-focus-ring-color
  }
}
@media (min-width: 960px) and (max-width: 1199px) {
  .dailyBlock {
    margin: 20px 10px;

    .titleBlock {
      display: block;
      letter-spacing: -0.013em !important;
      margin: 12px 0px 0px 0px;
      align-items: end !important;

      .myDailyText {
        display: inline-block;
      }

      .MuiButtonGroup-root {
        margin-right: 16px;

        .MuiButtonBase-root {
          letter-spacing: normal !important;
          width: 125px; // !important;
          text-transform: none !important;
          line-height: normal;

          &:first-child {
            padding-left: 0px;

            .MuiButton-startIcon {
              margin-left: -15px;
              margin-right: 10px;
            }
          }

          &:last-child {
            padding-left: 10px;
              padding-right: 10px;
            .buttonText {
              .calendarViewText{
                display: block;
              }
            }

            .MuiButton-startIcon {
              margin-left: -1px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 539px) {
  .buttonGroup{
    font-size: 0.8em;
  }

}

.dashboardBlock{
  .listBlock{
    display: flex;
    flex-wrap: wrap; 
    @media (max-width: 539px){
      justify-content: center;
    }
  }
}
