@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.homeTopBlock {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .appBlock {
    margin-top: 0;

    .title {
      font-weight: bold;
    }

    .phoneImg {
      margin-left: -48px
    }

    .appShare {
      font-family: $RedHatText;
      font-style: normal;
      font-weight: bold;
      margin-top: -42px;
      letter-spacing: 0.02em;
      padding-left: 109px;

      .title {
        font-size: 24px;
        line-height: 22px;
        margin-bottom: 25px;
        color: #43425D;
        font-weight: bold;
        margin-left: 45px;
      }

      .storesBlock {
        margin-left: 15px;

        img {
          margin-top: 0;
          width: 125px;
          height: 36px;
        }
      }
    }
  }

  .loginBlock {
    background: #FFFFFF;
    margin: 56px;
    width: 413px;
    filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15));
    border-radius: 12px;

    .loginForm {
      form {
        padding: 35px 44px 16px;

        .title {
          font-family: $RedHat;
          font-style: normal;
          font-weight: bold;
          font-size: 28px;
          line-height: 100%;
          text-align: center;
          color: #43425D;
          margin-bottom: 20px;
        }

        .line {
          margin-bottom: 20px;

          .passwordInput {
            width: 290px;
          }
        }

        .button-wrapper {
          margin-top: 4px;

          button {
            font-family: $RedHat;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #FFFFFF;
            padding: 8px 0;
          }

          .disactive {
            color: #CBC6D1;
          }
        }

        .forgotPassword {
          margin: 25px auto 0;
        }
      }
    }

    .grayBackground {
      padding: 20px 44px 3px;
      border-radius: 0px 0px 12px 12px;

      .button-wrapper {
        background: #598E7E;
        box-shadow: 0px 2px 5px rgba(70, 69, 95, 0.1);
        border-radius: 4px;
        border: 0;
        margin-bottom: 12px;

        button {
          font-family: $RedHat;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          color: #FFFFFF;
          border: 0;
        }
      }

      .socialTitle {
        margin: 0px auto;
      }

      .socialButtons {
        margin: auto;

        img {
          height: 63px;
          width: 63px;
          padding: 12px;
          margin: 0 11px;
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 539px) {
  .homeTopBlock {

    .loginBlock {
      margin-top: 0px;
      width: 100%;

      .loginForm {
        form {
          padding: 30px 10px 10px;
        }

        .grayBackground {

          border-radius: 0px 0px 12px 12px;
          padding: 26px 0px 0px 0px;

          .button-wrapper {
            margin-bottom: 11px;

            &.width100 {
              width: 300px;
            }
          }
        }
      }
    }

    .appBlock {
      margin: 5px 0px 36px 0px;

      .appShare {
        padding-left: 0px;
        text-align: center;

        .title {
          margin-left: 0px;
        }
      }

      .storesBlock {
        margin-left: 0px !important;
      }

      .phoneImg {
        display: none;
      }
    }
  }

}

@media (min-width: 540px) and (max-width: 719px) {
  .homeTopBlock {

    .loginBlock {
      margin-top: 83px;

      .loginForm {}
    }

    .appBlock {
      margin-top: 42px;
      margin-bottom: 54px;

      .appShare {
        padding-left: 0px;
        text-align: center;

        .title {
          margin-left: 0px;
        }
      }

      .storesBlock {
        margin-left: 0px !important;
      }

      .phoneImg {
        display: none;
      }
    }
  }
}

@media (min-width: 720px) and (max-width: 959px) {
  .homeTopBlock {

    .loginBlock {
      margin-top: 83px;
    }

    .appBlock {
      width: 390px;
      margin: 55px;

      .appShare {
        padding-left: 0px;
        margin-top: -56px;
        text-align: center;

        .title {
          margin-left: 0px !important;
        }
      }

      .storesBlock {
        margin-left: 0px !important;
      }

      .phoneImg {
        display: none;
      }
    }
  }

}

@media (min-width: 960px) and (max-width: 1199px) {
  .home-wrapper {
    .homeTopBlock {
      .appBlock {
        width: 388px;

        .appShare {
          margin-top: -22px;

          .title {}
        }

      }

      .loginBlock {
        margin: 53px -1px 34px 13px;

        .loginForm {}
      }
    }
  }
}

@media (min-width: 1200px) {
  .home-wrapper {
    .homeTopBlock {
      .appBlock {
        .appShare {
          margin-top: -22px;

          .title {}
        }

      }

      .loginBlock {
        margin: 54px 0px 35px 181px;

        .loginForm {}
      }
    }
  }






}
