@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.buttonsBlock {
  width: 100%;
  height: 100%;
  position: relative;

  .uploadImage {
    background-position: center;
    background-size: cover;
    border: 0;
    color: #7E7D8F;
    font-family: $RedHat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    height: 100%;
    line-height: 21px;
    padding-top: 74px;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  .clearImage {
    position: absolute;
    top: 10px;
    right: 10px;
    border: 0;
    padding: 0;
    background-color: inherit;
  }
}
