@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.createGroupPage {
  display: flex;
  flex-direction: column;
  margin: 40px auto 90px;

  .createGroupBlock {
    background: $white;
    border-radius: 4px;
    box-shadow: 0px 2px 8px $violet_dark_3;
    margin: 10px;
    max-width: 490px;
    padding: 20px 85px 30px;
    text-align: center;
  }

  .createGroupTitle {
    font-family: $RedHat;
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.03em;
    line-height: 26px;
    margin: 10px 0 20px;
    text-align: center;

    &.webinar {
      color: $res_end_special;
      letter-spacing: 0.1em;
    }

    &.support {
      color: $blue_end_special;
    }

    &.master_mind {
      color: $violet_end_special;
      

    }
  }

  .createGroupText {
    color: $violet_start;
    font-family: $RedHat;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    margin-bottom: 20px;
    text-align: center;
  }

  .creteGroupButton {
    .button {
      border: 0;
      color: $white;
      font-family: $RedHat;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.02em;
      line-height: 33px;
      padding-left: 10px;
      padding-right: 10px;
    }

    &.master_mind {
      .button {
        // background: linear-gradient(0.43deg, $violet_start_special 1.96%, $violet_end_special 102.09%);
        background: #4E519F;
        border-radius: 4px;
        box-shadow: 0px 2px 5px $violet_dark_3;
      }
    }

    &.webinar {
      .button {
        background: linear-gradient(0.43deg, $res_start_special 1.96%, $res_end_special 102.09%);

        box-shadow: 0px 2px 5px $violet_dark_3;
        border-radius: 4px;
        letter-spacing: 0.04em;
      }
    }

    &.support {
      .button {
        background: linear-gradient(0.43deg, $blue_start_special 1.96%, $blue_end_special 102.09%);
        box-shadow: 0px 2px 5px $violet_dark_3;
        border-radius: 4px;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 539px) {
  .createGroupPage {
    margin: 20px auto;

    .createGroupBlock {
      width: 320px;
      padding: 20px 10px 30px;
    }
  }
}
