@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.dialogConteiner {
  ::-webkit-scrollbar {
    display: none;
  }

  .dialogContent {
    height: 100%;
    padding: 0;
    overflow-x: hidden;

    &:first-child {
      padding: 0;
    }

    .dialodScrollBlock {
      height: 100%;
      width: 490px;
      // position: relative;
      // padding-bottom: 20px;

      .closeButton {
        position: absolute;
        top: 8px;
        // right: 8px;
        left: 89%;
        z-index: 3;
      }
    
      .closeButton:focus{
        outline: none;
      }
    }
  }
}

.streemDialog {
  height: 100%;
  width: 100%;

  .MuiDialog-paper {
    height: 100%;
    margin: 0;
    max-height: 100%;
    max-width: 100%;
    width: 100%;

    .dialogContent {
      align-items: center;
      background: #000000;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;
    }
  }
}

@media (min-width: 320px) and (max-width: 539px) {
  .dialogConteiner {
    .dialogContent {
      .dialodScrollBlock {
        width: 100%;
        .roundIcon {
          top: -1px;
          right: 0px;
        }
      }
    }
  }
}

@media (min-width: 540px) and (max-width: 719px) {
  .dialogConteiner {
    .dialogContent {
      .dialodScrollBlock {
        .roundIcon {
          top: -1px;
          right: 0px;
        }
      }
    }
  }
}

@media (min-width: 720px) and (max-width: 959px) {
  .dialogConteiner {
    .dialogContent {
      .dialodScrollBlock {
        .roundIcon {
          top: -1px;
          right: 0px;
        }
      }
    }
  }
}


@media (min-width: 960px) and (max-width: 1199px) {
  

}

@media (min-width: 1200px) {
}
