.addPaymentMetod {
  background: none;
  border: none;
  color: #598E7E;
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  margin: 8px;
  padding: 16px 0;
  display: flex;
  align-items: center;


  cursor: pointer;
  
  &:focus {
    outline: none;
  }

  .title_block{
    display: flex;
    flex-direction: column;
    margin-left: 16px;

  }
  .debit_only{
    font-size: 10px;
    line-height: 13px;
    color: #FF2D55;
    display: flex;
    align-items: center;
    span{
      margin-left: 5px;
    }
  }
}


.add_payment_method_modal_content{
  .title{
    width: fit-content;
    margin: 60px auto 0px auto;
    color: #43425D;
    font-weight: bold;
    font-size: 28px;
  }

  .cardForm{
    padding-top: 0 !important;
  }

  .select_type{
    display: flex;
    align-items: center;
    justify-content: center;
    .select_type_item{
      margin: 16px;
      display: flex;
      align-items: center;

      span{
        font-size: 18px;
        color: #43425D;
      }
    }
  }
}