@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.forgotForm {
  justify-content: end;

  input { // label
    align-items: flex-end;
    color: $violet_start;
    display: flex;
    font-family: $RedHat;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
  }

  form {
    justify-content: flex-start;
  }

  .subTitle {
    color: $violet_start;
    font-family: $RedHatText;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 21px;
    margin-top: 30px;
    text-align: center;
    width: 200px;
  }

  .titleImage {
    width: 300px;
  }

  .end {
    margin-bottom: 30px;
  }
  
  .signInBlock {
    margin: 0;
    padding: 20px 0 0 30px;
    text-align: left;
    width: 100%;

    span {
      font-family: $RedHat;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: #43425D;
      opacity: 0.5;
    }

    .resendCode {
      padding: 0;
    }
  }
}

.defaultForm.resetForm {
  .line {
    margin-top: 18px;
  }

  .signInBlock {
    padding: 20px 0 0 30px;

    div {
      padding-top: 11px;
    }
  }

  .line.end {
    margin-top: 23px;
  }

  .button-wrapper {
    button {
      font-family: $RedHat;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #ffffff;
      padding: 13px 0;
    }

    .disactive {
      color: #CBC6D1;
    }
  }
}

@media (min-width: 320px) and (max-width: 539px) {
  .forgotForm {
    margin-bottom: 57px;

    .titleImage {
      margin: 0px auto 27px;
    }

    .title {
      font-size: 29px;
    }

    .line.end {
      margin-bottom: 29px;
      margin-top: 37px;
    }
  }
}

@media (min-width: 540px) and (max-width: 719px) {
  .forgotForm {
    margin-bottom: 104px;

    .titleImage {
      margin: 30px auto 26px;
    }

    .title {
      font-size: 29px;
    }

    .line.end {
      margin-bottom: 27px;
      margin-top: 38px;
    }
  }
}

@media (min-width: 720px) and (max-width: 959px) {
  .forgotForm {
    margin-bottom: 102px;

    .titleImage {
      margin: 30px auto 27px;
    }

    .title {
      font-size: 29px;
      margin-top: -2px;
    }

    .line.end {
      margin-bottom: 29px;
      margin-top: 39px;
    }
  }
}

@media (min-width: 960px) and (max-width: 1199px) {
  .forgotForm {
    margin-bottom: 153px;

    .titleImage {
      margin: 30px auto 27px;
    }

    .title {
      font-size: 29px;
    }

    .line.end {
      margin-bottom: 28px;
      margin-top: 37px;
    }
  }

  .defaultForm.resetForm {
    .line {
      margin-top: 17px;
    }
  }
}

@media (min-width: 1200px) {
  .forgotForm {
    margin-bottom: 200px;

    .titleImage {
      margin: 30px auto 25px;
    }

    .title {
      font-size: 29px;
    }

    .line.end {
      margin-bottom: 30px;
      margin-top: 39px;
    }
  }
  .resetForm {
    .line.end {
      margin-top: 20px;
    }
  }
}