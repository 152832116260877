@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.groupType {
  padding: 4px;
  background: $violet_start;
  border-radius: 4px;
  font-family: $RedHat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: $white;
  margin: 10px 0px;
  display: inline-block;
  width: fit-content;
  
  &.webinar,
  &.recurring_webinar {
    background: #DD4B39;
  }

  &.support {
    align-items: center;
    background: #2E9DC0;
    display: block;

    span {
      display: inline-block;
      padding: 0px 2px;
    }
  }

  &.master_mind {
    background: #4E519F;
  }

  &.individual {
    align-items: center;
    background: $violet_end_special;
    display: inline-flex;

    span {
      color: #FFCA00;
    }
  }

  .private_label{
    display: flex; 
    align-items: center;
    color: #FFFFFF;
  }

  .groupType_format{
    color: rgba($white, .6);
    &.private{
      margin-left: 18px;
      opacity: 0.5;
    }
  }
}
