@import '../../const/colors.scss';
@import '../../const/fonts.scss';


.itemBlock {
  margin: 35px 20px 0;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(70, 69, 95, 0.3);
  margin-bottom: 20px;
  margin-top: 10px;
  padding-bottom: 10px;
  position: relative;
  display:flex;
  flex-direction: column;
  

  .subscriptionsIcon {
    position: absolute;
    width: 24px;
    height: 28px;
    right: -7px;
    top: 20px;
    z-index: 2;
    image {
      height: 100%;
    }
  }

  .imageBlock {
    align-content: space-between;
    background-color: black;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    box-shadow: 0px 2px 8px $violet_dark_25;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 0;
    overflow: hidden;
    position: relative;

    &_info {
      position: absolute;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 20px 23px;
      justify-content: space-between;
    }

    .owner {
      margin-left: 0;
    }
    .dateTime {
      margin-right: 0;
    }

    .row {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 10px;
    }

    .imageBackground {
      background: linear-gradient(180deg, $black0 0%, $black8 100%);
      bottom: 0;
      height: 100px;
      left: 0;
      opacity: 0.4;
      position: absolute;
      right: 0;
      width: 100%;
    }

    .imageBackgroundClickListener {
      background: linear-gradient(180deg, $black0 0%, $black8 100%);
      bottom: 0;
      height: 100%;
      left: 0;
      opacity: 0.4;
      position: absolute;
      z-index: 1;
      right: 0;
      width: 100%;
    }

    .title {
      width: 100%;
      display: flex;
      flex-flow: column;
      flex-grow: 1;

      .second {
        background: $black6;
        border-radius: 4px;
        color: $white;
        display: inline-block;
        font-family: $RedHat;
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
        line-height: 16px;
        padding: 4px;
        white-space: normal;
        //margin-right: 74%;
        width: fit-content;
        max-width: 100%;
      }
    }
  }
  .imageBlock_premium{
    position: absolute;
    top: 323px;
    z-index: 2;
    left:23px;
  }
  .contentBlock {
    padding: 13px 20px 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .detailsBlock {
      align-items: baseline;
      display: flex;
      flex-direction: column;

      &_owner {
        margin-bottom: 10px;
      }
    }

    .detailsDateTime {
      display: flex;
      flex-shrink: 0;
      flex-direction: row;
      gap:15px;
      .detailsDate {
        align-items: flex-start;
        color: #7e7d8f;
        display: flex;
        font-family: $RedHat;
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        line-height: 19px;
      }

      .detailsTime {
        align-items: flex-start;
        color: #7e7d8f;
        display: flex;
        font-family: $RedHat;
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        line-height: 19px;
        //margin-left: 30px;

        div {
          margin-bottom: 5px;
        }
      }
    }
    .eventName {
      margin-top: 7px;
      font-family: $RedHat;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: $violet_start;
      margin-bottom: 10px;
    }

    .eventTag {
      background: $violet_start;
      border-radius: 4px;
      font-size: 14px;
      line-height: 19px;
      color: $white;
      padding: 4px;
      width: fit-content;
      margin-top: 10px;
    }

    .unsubscribeButton {
      display: none;
    }
  }

  .button {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 1200px) {
  .itemBlock {
    //max-width: 400px;
    //min-width: 250px;
    .imageBlock {
      //width: 492px;
    }
  }
  .groupLsRecords{
    //max-width: 450px;
    min-width: 270px;
  }
}

.inPerson {
  display: flex;
  align-items: center;

  &_img {
    width: auto !important;
    margin-right: 5px;
  }

  &_text {
    color: #E9E9F0;
  }
}

.inPersonWrapper {
  margin: 10px;
}
