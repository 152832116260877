@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.personBlock {
  width: 100%;

  .personInfo {
    display: flex;
    padding: 15px 0;

    .personInfo-avatar {
      width: 50px;
      height: 50px;
    }

    .textInfo {
      margin-left: 10px;
      width: calc(100% - 60px);

      .title {
        font-family: Red Hat Text;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: $violet_start;
        width: 170px;
      }

      .location {
        font-family: $RedHatText;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 13px;
        color: $grey_normal;
        overflow-wrap: break-word;
      }

      .position {
        font-family: $RedHatText;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 13px;
        color: $violet_start;
        margin-top: 4px;
        overflow-wrap: break-word;
        // margin-right: 30px;
      }

      .MuiRating-root {
        color: $grey_normal;
        display: flex;
        margin-top: 7px;

        .MuiSvgIcon-fontSizeInherit {
          width: 8.5px;
          height: 8px;
        }
      }

      .titleAndConnect {
        width: 100%;
        display: flex;
        justify-content: space-between;
        a{
          max-width: 100%;
          .title{
            max-width: 100%;
          }

        }
        .button-wrapper {
          margin: 0;
        }
      }

      .button-wrapper {
        margin: 10px 0 5px;
      }
      .connectButtons {
        height: auto;
      }
    }
  }

  &:last-child {
    .MuiRating-root {
      display: none;
    }
  }
}
