@import '../../../const/colors.scss';
@import '../../../const/fonts.scss';

.postsBlock {
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(67, 66, 93, 0.1);
  margin-bottom: 20px;
  padding: 20px;

  .postBlock {
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(70, 69, 95, 0.3);
    margin-bottom: 20px;
    padding: 20px;
  
    .postHeader {
      display: flex;
      margin-bottom: 10px;

      .avatar {
        height: 32px;
        width: 32px;
        margin-right: 4px;
      }

      .usetData {
        margin-right: auto;
        .userTitle {
          color: #43425D;
          font-family: $RedHat;
          font-size: 16px;
          font-style: normal;
          font-weight: normal;
          line-height: 21px;
        }

        .postDate {
          font-family: $RedHat;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          color: #7E7D8F;
        }
      }

      .disBlock {
        margin-top: -12px;
        margin-right: -12px;
      }
    }

    .postTitle {
      color: #43425D;
      font-family: $RedHat;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      line-height: 21px;
      margin-bottom: 10px;
    }

    .postDesc {
      color: #43425D;
      font-family: $RedHat;
      font-size: 16px;
      font-style: normal;
      font-weight: normal;
      line-height: 21px;
      margin-bottom: 20px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      max-width: 465px;
    }

    .grid-container,
    .madiaBlock {
      margin-bottom: 20px;
      padding: 0;
    }

    .socialBlock {
      align-items: center;
      display: flex;
      margin-bottom: 20px;

      .like {
        align-items: center;
        display: flex;

        button {
          align-items: center;
          display: flex;
          border: 0;
          padding: 0;
          color: #7E7D8F;
          font-family: $RedHat;
          font-size: 12px;
          font-style: normal;
          font-weight: normal;
          line-height: 16px;
          margin-right: 20px;

          &:focus {
            outline: none;
          }
        }
      }

      .share {
        align-items: center;
        color: #7E7D8F;
        display: flex;
        font-family: $RedHat;
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
        line-height: 16px;
        cursor:pointer;
      }

      .liked {
        color: #7E7D8F;
        font-family: $RedHat;
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
        line-height: 16px;
        margin-left: auto;
      }
    }

    form {
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;
      padding: 0;

      input {
        border: 0;
        color: #43425D;
        font-family: $RedHat;
        font-size: 16px;
        font-style: normal;
        font-weight: normal;
        line-height: 21px;
        margin-right: auto;
        opacity: 0.3;
        width: 100%;

        &:focus {
          outline: none;
        }
      }
    }
  }
}


@media (min-width: 720px) and (max-width: 1199px) {
  .postsBlock {
    .postBlock {
      .postDesc {
        max-width: 265px;
      }
    }
  }
}