@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.lsrgTitle {
  display: flex !important;
  flex-flow: row !important;

  .second {
    margin-right: auto !important;
  }

  .iconButtonsBlock {
    margin-right: -12px;
    margin-top: -2px;
    margin-bottom: -14px;
    z-index: 10;
    transform: translateY(-6px);
  }
}

.LSRGroupData {
  padding: 20px 20px 0;

  .lsrgTitle {
    -ms-word-break: break-all;
    color: #43425D;
    display: flex;
    font-family: $RedHat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    justify-content: space-between;
    line-height: 21px;
    margin-top: 10px;
    margin-bottom: 10px;
    word-break: break-word;

    &-info{
      display: flex;

      p{
        padding: 0px 2px;
      }
    }

    .actionsButtons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: -6px;
      margin-right: -8px;
      margin-top: -6px;
      min-width: 62px;
 
      button {
        padding: 8px;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .meetingsText {
    -ms-word-break: break-all;
    color: #7E7D8F;
    font-family: $RedHat;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 19px;
    margin-bottom: 10px;
    word-break: break-all;
    word-break: break-word;
    text-align: justify;
  }

  .showContent {
    margin-bottom: 20px;
    margin-top: 25px;
  }

  .content_item_btns_wrapper{
    display: flex;
    column-gap: 20px;
  }
  .group_content_btns_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .group_content_socialBtns{
      position: relative;
      .button-wrapper{
        line-height: 40px;
      }
      button{
        &:focus,
        &:active{
          outline: none;
        }
      }
      .playlistGreyIcon{
        padding: 10px 0 12px 12px;
        cursor: pointer;
      }
      .share-popup-content{
        right:unset !important;
        top:35px;
      }
    }
  }
}

.show-hide {
  background: none;
  border: none;
  color: #598E7E;
  font-family: $RedHat;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  padding: 0;
  margin-bottom: 20px;

  &:focus {
    outline: none;
  }
}

.record{
  margin-bottom: 5px;
}
.contentBlock{
  .buyMediaGroups{
    background: transparent;
  }
}
.groupDetailsBlock{
  .buyMediaGroups {
    background: #F4A706;
  }
}
.buyMediaGroups {
  border-radius: 2px;
  border: 0;
  color: #FFFFFF;
  float: right;
  font-family: $RedHat;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 19px;
  padding: 2px 5px;
  z-index: 2;
  position: absolute;
  top: 320.1px;
  

  &:focus {
    outline: none;
  }
}

.itemBlock{
  .subscribePink{
    position: absolute;
    z-index: 10;
    right:-7px;
    top:20px
  }
  .addToPlaylistcontainer{
    z-index: 2;
    height: 100%;
    .playlist_wrapper{
      right:unset;
      bottom: unset;
      top:0;
      width:100%;
      height: 100%;
      z-index: 2;
      .playlist{
        width:100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .playlist_items{
          flex-grow: 1;
          max-height: unset;
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 539px) {
  .showContent {
    .button-wrapper {
      width: 100%;

      button {
        width: 100% !important;
      }
    }
  }
}
.groupDetailsBlock{
  .addToPlaylistcontainer{
    z-index: 10;
    height: 100%;
    .playlist_wrapper{
      right:0;
      bottom: unset;
      top:0;
      width:100%;
      height: 100%;
      z-index: 2;
      .playlist{
        width:100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .playlist_items{
          flex-grow: 1;
          max-height: unset;
        }
      }
    }
  }
}
.playlistGreyIcon{
  cursor: pointer;
}
