.input_with_icon{
    display: flex;

    img{
        margin-top: 16px;
    }
    .simpleInput{
        margin-left: 16px;
        width: 320px;
    }
    
    &.width320{
        width: 320px;
    }
}

.videoName{
    width: 100%;
}

.disableLikeActive {
    .Mui-disabled {
        color: rgba(0, 0, 0, 0.87);
    }

    .MuiInputBase-root.Mui-disabled:hover {
        &:before {
            border-bottom: 2px solid rgba(0, 0, 0, 0.87);
        }
    }
}
