@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.rightBox {
  height: 100%;
  margin: 30px 0 10px 10px;
  padding: 30px 20px 0px 20px;
}

.rightBlock {
  .rightBox {
    background: $white;
    border-radius: 5px;
    box-shadow: 0px 2px 5px $violet_start_1;
    margin: 20px 10px 10px;
    padding: 30px 20px 0px 20px;

    h2 {
      color: $violet_start;
      font-family: $RedHat;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      margin-bottom: 12px;
      text-align: center;
    }

    .rightBox-title {
      margin-top: 0px !important;
    }

    .desc {
      color: $grey_normal;
      font-family:$RedHat;
      font-size: 16px;
      font-style: normal;
      font-weight: normal;
      line-height: 21px;
      margin-bottom: 21px;
      margin-top: 19px;
    }

    .link {
      color: $green_normal;
      text-decoration: underline;
    }
    
    .linkBlock {
      display: block;
      margin-top: 18px;
    }

    .personBlock {
      .personInfo {
        display: flex;
        padding-top: 10px;

        .textInfo {
          margin-left: 10px;

          .title {
            font-family: $RedHatText;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: $violet_start;
          }

          .location {
            font-family: $RedHatText;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 13px;
            color: $grey_normal;
          }

          .position {
            font-family: $RedHatText;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 13px;
            color: $violet_start;
            margin-top: 4px;
            max-width: 150px;
          }
          
          .MuiRating-root {
            color: $grey_normal;
            display: flex;
            margin-top: 7px;

            .MuiSvgIcon-fontSizeInherit {
              width: 8.5px;
              height: 8px;
            }
          }

          .button-wrapper {
            margin-top: 5px;
            margin-bottom: 11px;
          }
        }
      }
    }

    .rightGroupItem {
      padding: 20px 0;

      .groupName {
        font-family: $RedHat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: $violet_start;
        overflow-wrap: break-word;
      }

      .groupDesc {
        font-family: $RedHat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: $grey_normal;
        overflow-wrap: break-word;
        white-space: pre-wrap
      }

      .groupTime {
        font-family: $RedHat;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 13px;
        color: $grey_normal;
      }

      .button-wrapper {
        margin-top: 10px;
        width: 100%;
      }

      .unsubscribeButton {
        display: none;
      }

      button {
        font-family: $RedHat;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        padding: 5px;
        width: 100%;
        text-transform: uppercase;
      }
    }
  }
  .follow {
    // padding-bottom: 10px;
  }
}

@media (min-width: 320px) and (max-width: 539px) {
  .rightBlock {
    // display: none;
    .rightBox {
      margin-top: 0;
    }
  }
}

@media (min-width: 540px) and (max-width: 719px) {
  .rightBlock {
    .rightBox {
      width: auto;
      margin: 0;
      background: $white;
      box-shadow: 0px 2px 5px $violet_start_1;
      border-radius: 5px;
      margin: 30px 10px 20px;
      padding: 28px 20px 0px;

      h2 {
        width: 200px;
      }
    }

    .follow {
      padding: 28px 20px 13px;
      h2 {
        width: 100%;
      }

      .personInfo {
        padding-top: 12px;
      }
    }
  }
}

@media (min-width: 720px) and (max-width: 959px) {
  .rightBlock {
    .rightBox {
      width: 220px;
    }
  }
}

@media (min-width: 960px) and (max-width: 1199px) {
  
    .rightBox {
      width: auto;
      margin: 0;
      background: $white;
      box-shadow: 0px 2px 5px $violet_start_1;
      border-radius: 5px;
      margin: 30px 10px 20px;
      padding: 28px 20px 0px;
     
    }

    .follow {
      padding: 30px 20px 0px;

      h2 {
        width: 100%;
        display: block;
        letter-spacing: -0.0241176em;
        margin-top: 0px;
      }

      .personInfo {
        padding-top: 12px;
      }
    }
  
  
}

@media (min-width: 1200px) {
  .rightBlock {
    .rightBox {
      width: 235px;
    }
  }
}

@media (min-width: 960px) and (max-width: 1030px) {
  .follow {
    width: 210px;
  }
}
