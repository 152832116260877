:root {
  --basic-width: 250px;
  --basic-height: 88px;
}

.share-popup-content {
  position: absolute;
  z-index: 10;
  top: 0;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(70, 69, 95, 0.3);
  border-radius: 4px;
  width: var(--basic-width);
  animation-name: container-animation;
  animation-duration: 0.25s;
  padding-top: 1px;

  .share-popup-content-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 20px;
    height: 40px;
    animation-name: fadeIn;
    animation-duration: 1s;
    outline: none;
    color: black;
    text-decoration: none;

    .share-icon {
      width: 15px;
      height: 15px;
    }

    .share-popup-content-item-text {
      padding-left: 10px;
      color: black;
      text-decoration: none;
    }
  }
}

@keyframes container-animation {
  from {
    width: 0px
  }

  to {
    width: var(--basic-width)
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
