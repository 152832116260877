@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.profileBlock {
  flex-grow: 1;
  
  .paymentBlock {
    .methodsBlock {
      background: $white;
      border-radius: 5px;
      box-shadow: 0px 2px 5px $violet_start_1;
      margin: 20px 10px 10px;
      padding: 27px 23px 32px;

      h3 {
        font-family: $RedHat;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 100%;
        color: #43425D;
        padding-bottom: 10px;
      }

      .cardsList {
        .button-wrapper {
          display: flex;
          justify-content: center;
          margin-top: 30px;

          button {
          width: 100%;
          max-width: 320px;
          padding: 13px 0;
          }
        }
      }
    }

    .transactionsBlock {
      background: $white;
      border-radius: 5px;
      box-shadow: 0px 2px 5px $violet_start_1;
      margin: 18px 10px 20px;
      padding-bottom: 1px;
    }
  }
}

.rightBlockProfile {
	border-radius: 5px;
	box-shadow: 0px 2px 5px $violet_start_1;
	margin: 20px 10px;
	padding: 26px 17px 14px;
  background: $white;
  width: 235px;

	.rightActionList {
		padding: 0;
		margin-bottom: 36px;

		.MuiListItem-root {
			padding: 0;
			margin-bottom: 12px;

			.MuiListItemIcon-root {
				min-width: 26px;
			}

			.MuiListItemText-primary {
				font-family: $RedHat;
        color: $violet_start;
        font-size: 16px;
        font-style: normal;
        font-weight: normal;
        line-height: 100%;
      }
    }
    .active {
      .MuiListItemText-primary {
        font-weight: bold;
      }
    }
	}

	.rightActionLogout {
    margin-top: 15px;
    margin-bottom: 0;

    .MuiListItem-button {
      margin-bottom: 0;
    }
	}
}

@media (min-width: 320px) and (max-width: 539px) {
  .profileBlock {
   
    
    .paymentBlock {
      .methodsBlock {
        .cardsList {
          padding: 0px;
        }
      }
    }
  }
  
  .rightBlockProfile {
    .rightActionList {
      .MuiListItem-root {
        .MuiListItemIcon-root {
          height: fit-content;
        }
  
        .MuiListItemText-primary {
          height: fit-content;
        }
      }
    }
  }
}

@media (max-width: 719px) {
  .mobilePaymentMenu {
    background: #FFFFFF;
    box-shadow: 0px 2px 5px rgba(67, 66, 93, 0.1) !important;
    border-radius: 5px !important;
    margin: 20px 10px 0 !important;
    padding: 4px;
    // width: 100%;

    .mobilePaymentOpenMenu {
      display: flex;
      flex-direction: column;
      padding-top: 0;

      .rightActionList {
        .MuiListItem-gutters {
          padding: 8px 0;

          .MuiListItemIcon-root {
            min-width: 20px;
          }

          .MuiTypography-root {
            font-family: 'Red Hat Text', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 539px) {
  .mobilePaymentMenu {
    height: fit-content;
  }
}
