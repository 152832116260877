// blue list
$blue: #61dafb;
$blue_normal_5: rgba(55, 58, 134, 0.5);
$blue_start: #373A86;
$blue_start_special: #3186A0;
$blue_end_special: #2E9DC0;

// black list
$black25: rgba(0, 0, 0, 0.25);
$black7: rgba(0, 0, 0, -0.7);
$black8: rgba(0, 0, 0, 0.8);
$black6: rgba(0, 0, 0, 0.6);
$black0: rgba(0, 0, 0, 0);

// grey list
$grey_light: #F4F4F7;
$grey_white: #e9e9f0;
$grey_violet: #373a47;
$grey_violet_light: #494851;
$grey_yellow: #b8b7ad;
$grey_blue_light: #bdc3c7;
$grey_normal: #7e7d8f;
$grey_blue: #b1b1bf;
$grey_disabled:  #A8A7BC;
$grey: #bdbdbd;
$grey_unsubscribe: #6A697E;
$grey_select_tags:#A1A1AE;

// green list
$green_light: #39ac31;
$green_normal: #598E7E;
$green_normal_2: rgba(62, 117, 101, 0.2); 

// violet list
$violet_simple0: rgba(78, 81, 159, 0);
$violet_simple_2: rgba(78, 81, 159, 0.2);
$violet_dark: #45445F;
$violet_start: #43425D;
$violet_end: #605E87;
$violet_dark_3: rgba(70, 69, 95, 0.3);
$violet_dark_1: rgba(70, 69, 95, 0.1);
$violet_dark_25: rgba(70, 69, 95, 0.25);
$violet_grey: #666585;
$violet_white: #CBC6D1;
$violet_start_5: rgba(67, 66, 93, 0.5);
$violet_start_1: rgba(67, 66, 93, 0.1);
$violet_start_special: #494B80;
$violet_end_special: #4E519F;
$violet_main_filter: #44435E;

  // rose list
$rose: #FE1E92;
$rose0: rgba(254, 30, 146, 0);
$rose_dark_0: rgba(126, 88, 107, 0);

// red list
$red_dark: #a90000;
$error: #f44336;
$red_light: #C4C4C4;
$red_normal: #DD4B39;
$res_start_special: #C54738;
$res_end_special: #DD4B39;
$red_deactivatet: #FF2D55;
$red_small_triange: #C02442;
$red_swincher_dark: #941029;

// white list 
$white: #FFFFFF;
$wite-red: #E5E5E5;
$white_blue: #E5E4FD;
$white_red_ligth: #DADADA;

//yellow
$yellow_dark_4: rgba(164, 104, 14, 0.4);
$yellow_start: #FFD35D;
$yellow_end: #FEDE8A;
$yellow_error: #FDCA0B
