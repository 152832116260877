@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.dailyBlock.search{
  padding: 20px;
  .listBlock{
    padding-bottom: 0;
    overflow: hidden;

  }
}

.mainPage {
  display: flex;
  background: $wite-red;
  justify-content: center;

  &.white {
    background: $white;
  }

  .connectButtons {
    height: 20px;

    .small {
      width: auto;
      padding: 3px 5px;
      margin-left: 10px;
    }

    .profile {
      .small {
        background: $white;
        color: $violet_start;
        border: 1px solid $grey_white;
        box-sizing: border-box;
        border-radius: 2px;
      }
    }

    .message {
      .small {
        background: $violet_start;
        border: 1px solid $violet_start;
        box-sizing: border-box;
        box-shadow: 0px 1px 5px $violet_start_5;
        border-radius: 2px;
      }
    }
  }

  .search_no_content {
    display: block;
    color: $grey_normal;
    font-size: 1.1em;
    padding: 10px 0;
  }
}

.dailyBlock.search>.listBlock.listBlock {
  display: block !important;
}