@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.popapCalendar {
  // width: 250px;
}

.datePickerComponent {
  align-items: baseline;
  display: flex;

  .customPickerBlock {
    width: 100%;

    button {
      display: none;
    }

    input {
      color: $violet_start_5 !important;
      padding: 0 !important;
      margin-bottom: 8px;
    }
  }

  .errorLine {
    border: 2px solid #f44336;
    width: 100%;
  }

  .withIcon {
    margin-left: 10px;
    width: 190px;
  }

  .withOutIcon {
    // width: 190px;
  }

  .MuiFormControl-root {
    // margin-left: 10px;
    // width: 190px;

    .MuiInputBase-root {
      margin-top: 4px;

      label {
        color: $grey_blue;
        font-family: $RedHat;
        font-size: 10px;
        font-style: normal;
        font-weight: normal;
        line-height: 13px;
      }

      input {
        color: $violet_start;
        font-family: $RedHat;
        font-size: 18px;
        font-style: normal;
        font-weight: normal;
        line-height: 24px;
        padding-top: 13px;
      }
    }
  }

  button {
    &:focus {
      outline: none;
    }
  }
}
