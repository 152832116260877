@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.defaultForm {
  margin: 0 auto;

  .titleImage {
    margin: 49px 49px 47px 49px;
    width: 200px;
  }

  .title {
    align-items: flex-end;
    color: $violet_start;
    display: flex;
    font-family: $RedHat;
    font-size: 28px;
    font-style: normal;
    font-weight: bold;
    line-height: 100%;
    text-align: center;
    margin-bottom: 4px;
  }

  .line {
    margin-top: 20px;
    position: relative;
    img {
      padding-top: 26px;
      height: 42px;
      width: 17px; // 26px
    }

    .phoneInput,
    .passwordInput,
    .codeBlock {
      font-family: $RedHat;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      color: #43425D;

      .MuiSelect-icon {
        margin-top: 5px;
      }

      .MuiFormHelperText-root {
        margin: 0;
      }
    }
    
    .agreeBlock {
      margin-bottom: 30px;
      margin-top: -10px;

      label {
        color: $violet_start;
        font-family: $RedHat;
        font-size: 18px;
        font-style: normal;
        font-weight: normal;
        line-height: 24px;
        margin-bottom: 0;

        a {
          text-decoration: none;
          color: $violet_start;
          font-weight: bold;
        }
      }

      p {
        margin-left: 30px;
      }

      .MuiFormControlLabel-root {
        margin-right: 0;

        .MuiTypography-body1 {
          color: $violet_start;
          font-family: $RedHat;
          font-size: 18px;
          font-style: normal;
          font-weight: normal;
          line-height: 24px;
          padding-top: 13px;
          padding-left: 8px;
        }
      }
    }

    .verifyCodeInput {
      margin: 0 7.5px;
      width: 60px;

      input {
        align-items: flex-end;
        color: $violet_start;
        display: flex;
        font-family: $RedHat;
        font-size: 18px;
        font-style: normal;
        font-weight: normal;
        line-height: 24px;
        text-align: center;
      }
    }
    .loginInfoButton{
      position: absolute;
      right:0;
      bottom:4px;
    }
    .hoverLoginInfoButton{
      display: none;
      position: absolute;
      right: 2px;
      color: white;
      bottom: 27px;
      width: 150px;
      height: 56px;
      padding: 0;
    }
    .loginInfoButton:hover + .hoverLoginInfoButton{
      display: block;
    }
    &.borderBottomLogin .simpleInput .MuiInputBase-root{
      &:before{
        left: 0;
        right: 0;
        bottom: -5px;
        content: "\00a0";
        position: absolute;
        transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        pointer-events: none;
        border-bottom-color: #E9E9F0;
      }
    }
    #full_name{
      max-width: 270px;
    }
  }

  .codeError {
    color: $error;
    font-family: $RedHat;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 13px;
    margin: 5px 0;
  }

  .lineSeparatop {
    margin-bottom: 30px;
  }

  .signInBlock {
    color: $violet_start;
    display: flex;
    font-family: $RedHat;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 21px;
    margin: 50px 0;
    text-align: center;
    letter-spacing: 0.02em;

    span {
      font-family: $RedHat;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: #43425D;
      opacity: 0.5;
    }

    .signin {
      font-weight: bold;
      cursor: pointer;
      opacity: 1;
    }

    .resendCode {
      background: none;
      border: none;
      color: $green_normal;
      font-family: $RedHat;
      font-size: 16px;
      font-style: normal;
      font-weight: normal;
      line-height: 21px;
      text-align: center;
    }
  }
  .oneLine {
    align-items: flex-end;
  }

  .multyLine {
    flex-direction: column;
  }

  .text {
    color: $violet_start;
    font-family: $RedHat;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    justify-content: center;
    line-height: 16px;
  }
}

.registerForm {
  img {
    margin: 0;
  }

  .line {
    margin-top: 23px;
    align-items: end;

    img {
      padding-top: 20px;
    }
  }
}

.verifyForm {
  .title {
    letter-spacing: 0.01em;
  }

  .line {
    margin-top: 25px;
  }

  .line.text {
    margin-top: 53px;
  }

  .lineSeparatop {
    margin-bottom: 22px;
  }

  .multyLine {
    div {
      margin-top: 10px;
    }
  }

  .signInBlock {
    margin-top: 48px;
  }
}

@media (min-width: 320px) and (max-width: 539px) {
  .defaultForm {
    width: 300px;
  }

  .line {
    .simpleInput {
      height: 46px;
      width: 270px;
    }

    .codeBlock {
      width: 85px;
    }

    .phoneInput {
      width: 175px;
    }
  }
}

@media (min-width: 540px) and (max-width: 719px) {
  .defaultForm {
    margin-bottom: 50px;
    width: 320px;

    .line {
      .simpleInput {
        height: 46px;
        width: 290px;
      }

      .codeBlock {
        width: 85px;
      }

      .phoneInput {
        width: 195px;
      }
    }
  }
}

@media (min-width: 720px) and (max-width: 959px) {
  .defaultForm {
    margin-bottom: 50px;
    width: 340px;

    .line {
      .simpleInput {
        height: 46px;
        width: 310px;
      }

      .codeBlock {
        width: 85px;
      }

      .phoneInput {
        width: 215px;
      }
    }
  }

  .registerForm {
    padding: 60px 0px 20px;
  }
}

@media (min-width: 960px) and (max-width: 1199px) {
  .defaultForm {
    margin-bottom: 50px;
    width: 300px;
  }

  .registerForm {
    padding: 60px 0px 20px;
  }

  .line {
    .simpleInput {
      height: 46px;
      width: 270px;
    }

    .codeBlock {
      width: 85px;
    }

    .phoneInput {
      width: 175px;
    }
  }

  .verifyForm {
  
    .titleImage {
      margin-top: 40px;
    }
  }
}

@media (min-width: 1200px) {
  .defaultForm {
    margin-bottom: 50px;
    width: 320px;
    padding: 0;

    .line {
      .simpleInput {
        height: 46px;
        width: 290px;
      }

      .codeBlock {
        width: 85px;
      }

      .phoneInput {
        width: 195px;
      }
    }
  }

  .verifyForm {
  
    .titleImage {
      margin-top: 40px;
    }
  }
}
