@import '../../const/colors.scss';
@import '../../const/fonts.scss';

.dashboardBlock {
  flex-grow: 1;

  .titleBlock {
    align-items: baseline;
    color: $violet_start;
    display: flex;
    font-family: $RedHat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    justify-content: space-between;
    text-align: center;
  }
}

@media (min-width: 720px) and (max-width: 959px) {
  .dashboardBlock {
    margin: 20px 0px 20px 10px;
  }
}

@media (min-width: 960px) and (max-width: 1199px) {
  .dashboardBlock {
    margin: 0px 0px 20px -5px;

    .titleBlock {
      font-weight: 600;
      letter-spacing: -0.013em !important;
    }
  }

  .dailyBlock .titleBlock {
    
  }
}
