@import '../../../const/colors.scss';
@import '../../../const/fonts.scss';

.mm_bio {
  background: $white;
  border-radius: 5px;
  box-shadow: 0px 2px 5px $violet_start_1;
  margin: 20px 0;
  padding: 30px 20px 100px;

  .bio_text {
    font-size: 18px;
    line-height: 24px;
    color: #43425D;
    white-space: pre-wrap;
  }

  .linkedin {
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #F4F4F7;
    box-sizing: border-box;
    color: #598E7E;
    cursor: pointer;
    font-family: $RedHat;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    padding: 15px;

    span {
      margin-left: 10px;
    }
  }
}

@media (min-width: 720px) and (max-width: 959px) {
  .mm_bio {
    margin-top: 0;
  }
}

@media (min-width: 960px) and (max-width: 1199px) {
  .mm_bio {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .mm_bio {
    margin-top: 0;
  }
}
